const en = {
    code: "EN",
    name: "English",
    select_locale: "Change language to English",

    header: {
        title: "Research Access Management",
        links: {
            login: "Log in",
            system: "System",
            impersonate: "Impersonate",
            createCollaboration: "Create collaboration",
            requestCollaboration: "Request collaboration",
            requestService: "Register service",
            help: "Help",
            profile: "Profile",
            logout: "Logout",
            feedback: "Provide feedback",
            helpUrl: "https://edu.nl/vw3jx"
        },
    },
    breadcrumb: {
        home: "Home",
        registration: "Registration",
        collaborations: "Collaborations",
        collaboration: "{{name}}",
        collaborationJoinRequest: "Join request for '{{name}}'",
        organisations: "Organisations",
        organisation: "{{name}}",
        collaborationRequest: "Collaboration request '{{name}}'",
        services: "Services",
        service: "{{name}}",
        serviceRequest: "Service requests",
        group: "Group '{{name}}'",
        newGroup: "New group",
        profile: "Profile",
        aup: "AUP",
        history: "History",
        system: "System",
        confirmation: "Admin confirmation",
        newOrganisation: "New organisation",
        requestService: "Register service",
        newService: "New service",
        newCollaboration: "New collaboration",
        newCollaborationRequest: "New collaboration request",
        editCollaboration: "Edit collaboration",
        impersonate: "Impersonate",
        organisationInvite: "Invite admins",
        organisationInvitation: "Invitation",
        serviceInvite: "Invite admins",
        serviceInvitation: "Invitation",
        invite: "Invite members",
        invitation: "Invitation",
        users: "Users",
        userToken: "Service token '{{name}}'",
        myRequests: "My requests"
    },
    login: {
        title: "Research Access Management",
        subTitle: "Please log in...",
        suspended: "Your profile has been suspended. Please contact sram-support@surf.nl to resolve this",
        rateLimited: "You have attempted the TOTP verification too many times, and therefore you are logged out and your profile has been suspended. Contact your admin to resolve this",
        successfullyActivated: "You have re-activated your profile",
        closeBrowser: "To finish your logout you'll have to close your browser.",
        closeBrowserAfterDelete: "To finish the deletion of your profile you'll have to close your browser."
    },
    home: {
        sram: "SURF Research Access Management",
        edit: "Edit",
        history: "History",
        historyLink: "View history",
        unsuspend: "Revert suspension",
        avoidSuspending: "Avoid suspension",
        resetLastActivity: "Reset activity",
        otherOptions: "Other options",
        details: "Details",
        backToHome: "Back to home",
        firstTime: "Onboarding",
        collaborationRequest: "Request for new collaboration",
        noOrganisations: "To request a new collaboration it is required that institution '{{schac_home}}' where you logged in, can create and manage collaborations. This does not seem to be the case. Please contact <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a> for more information",
        noShacHome: "To request / create a new collaboration it is required that your institution releases the schacHomeOrganization attribute. This does not seem to be the case. Please contact <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a> for more information",
        noOrganisationsPlatformAdmin: "To create a new collaboration it is required you first create a new organisation.",
        tabs: {
            groups: "Groups",
            collaborations: "Collaborations",
            organisations: "Organisations",
            services: "Services",
            platformAdmins: "Platform admins",
            orgAdmins: "Organisation admins",
            orgServices: "Services",
            serviceAdmins: "Admins",
            orgCollaborations: "Collaborations",
            coServices: "Services",
            coAdmins: "Admins",
            members: "Members",
            users: "Users",
            serviceOrganisations: "Access rules",
            serviceCollaborations: "Connected collaborations",
            userTokens: "Service tokens",
            collaborationRequests: "Requests",
            joinRequests: "Join requests",
            myRequests: "My requests",
            serviceRequests: "Service registration",
            serviceConnectionRequests: "Connection requests",
            me: "Your details",
            about: "About",
            apikeys: "API tokens",
            welcome: "Welcome",
            cron: "Cron",
            seed: "Seed",
            database: "Database",
            activity: "Activity",
            validation: "Actions",
            plsc: "LDAP",
            composition: "Config",
            suspendedUsers: "Users",
            userlogins: "Logins",
            details: "Details & settings",
            orgUsers: "Users",
            scim: "SCIM",
            stats: "Stats"
        }
    },
    forms: {
        submit: "Add",
        save: "Save",
        apply: "Apply",
        ignore: "Ignore changes",
        request: "Request",
        cancel: "Cancel",
        close: "Close",
        showMore: "More",
        hideSome: "Less",
        open: "Open",
        update: "Update",
        today: "Today",
        manage: "Configure",
        invalidInput: "Invalid {{name}}",
        attributes: {
            email: "email address",
            uri: "URL (start with 'https://', for example)",
            contact: "contact (either email or URL)"
        },
        back: "Back",
        reset: "Reset",
        yes: "Yes",
        upload: "Upload image",
        uploadSSH: "Add SSH key",
        add: "Add an image",
        change: "Change image",
        image: "Image must be smaller then 2MB.",
        dragImage: "Drag and resize the crop area until you're happy with the result. Then hit apply.",
        imageToLarge: "Image is larger then 2MB.",
        imageDeleteConfirmation: "Are you sure you want to delete this image? This cannot be reverted, and you'll have to select a new image.",
        imageRequired: "Image is required",
        whiteSpace: "Fit the image",
        no: "No"
    },
    explain: {
        title: "Explanation {{subject}}",
        impersonate: "Impersonate",
        services: "Services",
        serviceRequest: "Service request",
        apiKeys: "Organisation API tokens",
        tokens: "Service tokens",
        serviceGroups: "Service groups"
    },
    models: {
        users: {
            name_email: "Name / email",
            blank: "",
            icon: "",
            profile: "Your profile",
            username: "Your username: ",
            subProfile: "Created on {{date}}",
            subOtherProfile: "The profile of {{name}} was created at {{date}}",
            institute: "Institution",
            instituteUnknown: "unknown",
            instituteUnknownMeTooltip: "Your organisation did not release the identifying attribute 'schacHomeOrganization'.",
            instituteUnknownTooltip: "The organisation of this user did not release the identifying attribute 'schacHomeOrganization'.",
            role: "Role",
            title: "Platform admins",
            platformAdmin: "Platform admin",
            noEntities: "There are no members yet...",
            searchPlaceHolder: "Search admins...",
            new: "New Member",
            me: "You",
            userTooltip: "Member {{username}} since {{date}}",
            inviteTooltip: "Invite for {{email}} send by {{name}} on {{date}}"
        },
        allUsers: {
            name_email: "Name / email",
            name: "Name",
            email: "Email",
            entitlement: "Entitlement",
            eduperson_principal_name: "Eduperson principal name",
            institute: "Institution",
            schac_home_organisation: "Institution",
            found: "{{count}} {{plural}} found",
            singleUser: "user",
            multipleUsers: "users",
            singleInvitation: "invitation",
            multipleInvitations: "invitations",
            and: " and ",
            organisation: "All collaboration users",
            title: "Users",
            last_login_date: "Last login date",
            affiliation: "Affiliation",
            username: "Username",
            uid: "Uid",
            noEntities: "First search for users...",
            noEntitiesInvitations: "First search for users or invitations...",
            searchPlaceHolder: "Search users...",
            me: "You",
            moreResults: "There are more results then shown, please refine your search.",
            noResults: "No users were found...",
            noResultsInvitations: "No users or invitations were found...",
            activity: "User history",
            loading: "Please wait, loading all history....",
            showSsh: "Show",
            ssh: {
                title: "Public SSH keys of {{name}}"
            }
        },
        orgMembers: {
            title: "Organisation admins & managers",
            noEntities: "There are no admins or managers in this organisation yet...",
            searchPlaceHolder: "Search members...",
            status: "Status",
            inviteSend: "Invited on {{date}}",
            expiredAt: "Invite expired",
            membershipNoExpiry: "Never expires",
            membershipExpiresAt: "Expires on {{date}}",
            membershipExpiredAt: "Expired on {{date}}",
            accepted: "Active",
            expired: "Expired",
            expires: "Expires",
            suspended: "Suspended",
            new: "Invite admins",
            remove: "Delete",
            resend: "Resend invitations",
            mail: "Compose email",
            backToMembers: "Back to all members...",
            invitation: "Invitation sent by {{inviter}} to {{email}} on {{date}}",
            removeTooltipDisabled: "First select users to delete",
            removeInvitationTooltip: "Delete invitation",
            editManagerTooltip: "Edit manager",
            removeMemberTooltip: "Delete member",
            removeTooltip: "Delete all selected members",
            mailTooltipDisabled: "First select users to email",
            mailTooltip: "Email all selected members",
            mailAdminTooltip: "Email admins",
            mailMemberTooltip: "Email member",
            mailInvitationTooltip: "Email invitee",
            resendTooltipDisabled: "First select expired invitations to resend",
            resendTooltip: "Resend all selected expired invitations",
            resendInvitationTooltip: "Resend invitation",
        },
        orgInvitations: {
            title: "Open organisation invitations",
            noEntities: "There are no open organisation invitations...",
            searchPlaceHolder: "Search invitations...",
            backToMembers: "Back to open invitations...",
            orgName: "Organisation"
        },
        organisationsWithoutAdmin: {
            title: "Organisations without administrators",
            noEntities: "There are no organisations without admins...",
            searchPlaceHolder: "Search organisations...",
        },
        servicesWithoutAdmin: {
            title: "Services without administrators",
            noEntities: "There are no services without admins...",
            searchPlaceHolder: "Search services...",
        },
        organisations: {
            title: "Organisations",
            noEntities: "There are no organisations yet...",
            searchPlaceHolder: "Search organisations...",
            new: "Add organisation",
            name: "Name",
            memberCount: "Admins",
            collaborationCount: "Collaborations",
            schacHomeOrganisations: "Org domains",
            category: "Category",
            leave: "Leave organisation"
        },
        services: {
            title: "Services",
            noEntities: "There are no services yet...",
            searchPlaceHolder: "Search services...",
            new: "Add service",
            request: "Register service",
            name: "Name",
            status: "Status",
            connectionRequestCount: "Connection requests",
            organisationCount: "Used in organisations",
            collaborationCount: "Used in collaborations",
            titleAvailableColl: "Available for this collaboration ({{count}})",
            titleRequestedColl: "Requested by members for this collaboration",
            titleAvailableOrg: "Available for this organisation",
            titleUsedColl: "Used by this collaboration",
            titleUsedOrg: "Mandated by this organisation",
            awaitingApproval: "Connection awaiting approval",
            automaticConnectionAllowed: "Collaborations can connect without approval",
            requiredByOrganisation: "Enabled by organisation",
            memberServiceRequest: "Service connection request by an admin",
            removeFromCO: "Disconnect",
            deleteConnectionRequest: "Delete connection request",
            resendConnectionRequest: "Resend connection request",
            addToCO: "Connect",
            removeFromOrg: "Remove from organisation",
            addToOrg: "Add to organisation",
            mandatory: "Mandatory for all collaborations",
            requestConnection: "Request",
            backToServices: "Back to services",
            connectionRequest: "Request connection to {{name}}",
            confirmations: {
                add: "Are you sure you want to make {{service}} available for all members of {{name}}?",
                remove: "Are you sure you want to remove {{service}} from {{name}}?",
                check: "I know about the <a target=\"_blank\" href=\"{{aup}}\">AUP</a> of {{name}}",
                noPolicy: "This service did not provide a privacy policy"
            },
            statuses: {
                active: "Enabled by organisation",
                pending: "Pending",
            },
            missingServices: "<a href=\"mailto:sram-support@surf.nl\">Send us your request</a> if you think a specific service is missing and should be listed."
        },
        collaborations: {
            title: "Collaborations",
            titleForOrgAdmin: "In collaborations managed by your organisation",
            searchPlaceHolder: "Search collaborations...",
            new: "Add collaboration",
            noEntities: "No collaborations found",
            newCollaborationRequest: "New request for collaboration",
            name: "Name",
            memberCount: "Members",
            invitationsCount: "Invitations",
            allMembers: "All groups",
            group: "Group {{name}} ({{count}})",
            hideInvites: "Hide invitations",
            noCollaborations: "There are no collaborations yet within your organisation. Why don't you create the first one?",
            noCollaborationsRequest: "There are no collaborations yet within your organisation. Why don't you request the first one?",
            noCollaborationsUser: "You are not member of any collaboration. Why don't you create one?",
            noCollaborationsRequestUser: "You are not member of any collaboration. Why don't you request one?",
            allLabels: "All labels",
        },
        serviceOrganisations: {
            searchPlaceHolder: "Search for organisations",
            title: "Organisations",
            availableForAll: "Make available for all organisations",
            notAvailableForAll: "Do not make available for all organisations",
            disableAccessConfirmation: "Are you sure you want disable access for this service?",
            disableAccessConsequences: "The following organisations and collaborations are using this service and won't be able to use it anymore.",
            collaboration: "collaboration",
            organisation: "organisation",
            noEntities: "There are no organisations using this service",
            permissions: {
                eachOrganisation: "Choose permission for each organisation",
                allowAllRequests: "Manually approve connection requests from collaborations for all organisations",
                allowAll: "Always allow connections from collaborations for all organisations"
            },
            options: {
                header: "Connections from collaborations",
                DISALLOW: "Disallow",
                ON_REQUEST: "On request",
                ALWAYS: "Always"
            }
        },
        memberCollaborations: {
            searchPlaceHolder: "Search for collaborations...",
            title: "Collaborations",
            new: "Request new collaboration",
            noEntities: "There are no collaborations",
        },
        serviceCollaborations: {
            searchPlaceHolder: "Search for collaborations...",
            title: "Collaborations using this service",
            organisationName: "Organisation",
            noEntities: "There are no collaborations using this service",
            organisationWarningTooltip: "This collaboration has access, because the organisation of this collaboration has granted access to all collaborations in this organisation.</br></br>To disconnect this collaboration, disallow access to the organisation in the Organisation tab.",
            origin: "Enabled by",
            fromOrganisation: "Organisation",
            fromCollaboration: "Collaboration",
            disconnect: "Disconnect",
            disconnectTooltip: "Disconnect all selected collaborations",
            disconnectOneTooltip: "Disconnect collaboration",
            confirmation: {
                remove: "Are you sure you want to disconnect the selected collaborations from this service? This can not be undone.",
                removeOne: "Are you sure you want to disconnect the collaboration {{name}} from this service? This can not be undone."
            },
            flash: {
                removed: "The collaboration(s) are disconnected"
            }
        },
        coAdmins: {
            searchPlaceHolder: "Search for admins...",
            title: "Admins",
            new: "Invite admins",
            noEntities: "There are no admins",
        },
        members: {
            searchPlaceHolder: "Search for members...",
            title: "Members",
            new: "Invite members",
            noEntities: "There are no members",
        },
        servicesAvailable: {
            title: "Available services",
            searchPlaceHolder: "Search for services...",
            noEntities: "No services are available to add"
        },
        servicesUsed: {
            searchPlaceHolder: "Search for services...",
            noEntities: "No services are being used"
        },
        servicesRequested: {
            searchPlaceHolder: "Search for services..."
        },
        groups: {
            backToGroups: "Back to all groups",
            title: "Groups",
            searchPlaceHolder: "Search for groups...",
            noEntities: "No groups were found",
            new: "Add group",
            edit: "Edit",
            name: "Name",
            urn: "Platform identifier",
            description: "Description",
            member: "Member",
            memberCount: "Members",
            service_group: "For Service",
            autoProvisioning: "Auto provisioning",
            on: "On",
            off: "Off",
            deleteMemberConfirmation: "Are you sure you want to remove {{name}} from this group?",
            deleteMembersConfirmation: "Are you sure you want to remove the selected members from this group?",
            deleteGroupConfirmation: "Are you sure you want to delete the group {{name}}?",
            deleteGroupsConfirmation: "Are you sure you want to delete the selected groups?",
            removeGroupTooltip: "Delete group",
            removeTooltip: "Delete all selected groups",
        },
        groupMembers: {
            title: "Members",
            searchPlaceHolder: "Search for members",
            noEntities: "No members",
            addMembersPlaceholder: "Search and select to add members"
        },
        collaboration: {
            memberHeader: "{{nbrMember}} members – {{nbrGroups}} groups",
            memberInformation: "Contact an administrator",
            noAdminsHeader: "No admins (yet)",
            adminsHeader: "Organised by <a style=\"display:inline;\" href=\"mailto:{{bcc}}{{mails}}\"><strong>{{name}}</strong></a>",
            twoAdminsHeader: "Organised by <a style=\"display:inline;\" href=\"mailto:{{bcc}}{{mails}}\"><strong>{{name}}</strong> and <strong>{{nbr}} other</strong></a>",
            multipleAdminsHeader: "Organised by <a style=\"display:inline;\" href=\"mailto:{{bcc}}{{mails}}\"><strong>{{name}}</strong> and <strong>{{nbr}} others</strong></a>",
            about: "What our collaboration is about",
            services: "We collaborate in {{nbr}} services",
            noServicesYet: "Where we collaborate",
            noServices: "There are no services connected to your collaboration. ",
            connectFirstService: "Why don't you connect one?",
            noServicesJoinRequest: "Services are only visible for members",
            servicesStart: "Click the button to start the service, or read the instructions first.",
            instructions: "Instructions",
            servicesHoover: "Visit this service at {{uri}}",
            members: "Members ({{nbr}})",
            showMemberDetails: "Show all details",
            admin: "admin",
            more: "Show {{nbr}} more",
            less: "Show less",
            viewAsMember: "View as member",
            viewAsAdmin: "View as admin",
            discloseNoMemberInformation: "This collaboration discloses no member information",
            discloseNoMemberInformationJoinRequest: "Only visible for members",
            newCollaborationRequest: "Request collaboration",
            leave: "Leave collaboration",
            labels: "Labels",
        },
        apiKeys: {
            title: "Organisation API tokens",
            new: "Add organisation API token",
            searchPlaceHolder: "Search for organisation API tokens...",
            noEntities: "No organisation API tokens",
            backToApiKeys: "Back to all organisation API tokens"
        },
        collaboration_requests: {
            title: "Requests for collaborations",
            requester: "Requester",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No requests for collaborations",
        },
        service_requests: {
            title: "Service registration",
            requester: "Requester",
            protocol: "Protocol",
            searchPlaceHolder: "Search for service registration...",
            noEntities: "No requests for registration",
        },
        member_collaboration_requests: {
            title: "My requests for collaborations",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No requests for collaborations",
        },
        my_requests: {
            title: "My requests ",
            searchPlaceHolder: "Search for requests...",
            noEntities: "You have made no requests",
        },
        system_collaboration_requests: {
            title: "Outdated open requests for collaborations",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No outdated requests for collaborations",
        },
        deleted_collaboration_requests: {
            title: "Deleted requests for collaborations",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No outdated denied / approved requests for collaborations",
        },
        invitation: {
            welcome: "Welcome to SURF Research Access Management. Here access to research services for research collaborations is managed.",
            invited: "You have been invited to join {{type}} <strong>'{{collaboration}}'</strong> by <a href=\"mailto:{{email}}\">{{inviter}}</a>",
            followingSteps: "SURF Research Access Management gives members of research collaborations fast and secure access to research services.</br></br>You can log in with your institution account or eduID.",
            steps: {
                progress: "{{now}} of {{total}}",
                login: "Log in",
                next: "Next: {{step}}",
                invite: "Join the collaboration",
                inviteOrg: "Join the organisation",
                inviteService: "Manage your service",
                collaborate: "Collaborate!"
            },
            login: "Please log in to identify yourself",
            loginWithSub: "Log in to accept the invite",
            loginTip: "You can use your institution account to log in, or use a guest account like eduID",
            accept: "I accept these terms",
            noAccept: "No, I don't agree",
            acceptInvitation: "Yes, I accept the invitation",
            declineInvitation: "No, I decline",
            confirmations: {
                cancelInvitation: "Are you sure you want to decline the terms?",
                declineInvitation: "Are you sure you want to decline the invitation?",
            },
            disclaimer: "If you continue, SURF will transfer data about you to the collaboration. Any data processing by the collaboration, like transferring data to services so you can access those, is a responsibility of the collaboration.",
            disclaimerAup: "The collaboration has provided SURF <a href=\"{{aup}}\" target=\"_blank\">the following URL</a> as holding relevant information.",
            disclaimerQuestions: "Any questions about transfer of data about you? Contact the person that invited you."
        },
        joinRequests: {
            backToJoinRequests: "Back to all join requests",
            title: "Join Request",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No join requests were found",
            edit: "Approve / decline",
            details: "Join request made by {{name}} on {{date}}"
        },
        memberJoinRequests: {
            title: "My collaboration join requests",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No join requests were found",
            collaborationName: "Collaboration name",
            requested: "Requested at",
            organisationName: "Institution"
        },
        systemJoinRequests: {
            title: "Outdated collaboration join requests",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No outdated join requests",
        },
        deletedJoinRequests: {
            title: "Deleted collaboration join requests",
            searchPlaceHolder: "Search for requests...",
            noEntities: "No outdated approved / denied join requests",
        },
        serviceConnectionRequests: {
            backToServiceConnectionRequests: "Back to all connection requests",
            title: "Connection requests",
            searchPlaceHolder: "Search for connection requests...",
            noEntities: "No service connection requests were found",
            edit: "Approve / decline",
            details: "Service connection request made by {{name}} on {{date}} for collaboration {{collaborationName}}",
            detailsWithService: "Service connection request made by {{name}} on {{date}} for collaboration {{collaborationName}} and service {{serviceName}}",
            requester: "Requester",
            name: "Collaboration",
            serviceName: "Service"
        },
        serviceGroups: {
            backToGroups: "Back to all service groups",
            autoProvisionMembersTooltip: "Check to ensure all existing and future new collaboration members are added to this group when it is created",
            title: "Service groups",
            searchPlaceHolder: "Search for service groups...",
            noEntities: "No service groups were found",
            new: "Add service group",
            edit: "Edit",
            name: "Name",
            description: "Description",
            autoProvisioning: "Auto provision users",
            on: "On",
            off: "Off"
        },
        serviceAdmins: {
            searchPlaceHolder: "Search for service admins..",
            noEntities: "There are no service admins or managers",
            new: "Invite admins",
            title: "Service admins & managers"
        },
        userTokens: {
            backToUserTokens: "Back to all service tokens",
            title: "Service tokens",
            searchPlaceHolder: "Search for service token...",
            noEntities: "No service tokens were found",
            new: "Create service token",
            edit: "Edit",
            name: "Name",
            namePlaceholder: "The name of this service token",
            description: "Description",
            descriptionPlaceholder: "The description of this service token",
            deleteConfirmation: "Are you sure you want to delete {{name}}?",
            expiryDate: "Expiry date",
            expiredInfo: "This service token has expired. You can reactivate it below",
            reactivate: "Reactivate",
            createdAt: "Created at",
            expiryDateTooltip: "After this date you cannot use this service token anymore",
            service: "Service",
            lastUsedDate: "Last used",
            serviceTooltip: "The service linked to this token. You can only access the API of this service with the token",
            hashedToken: "Service token",
            hashedTokenTooltip: "The service token to use in the authorization header",
            tokenDisclaimer: "Copy the service token and store it somewhere safe. You can view this token only once. After pressing <i>Save</i> you won't be able to see it again.",
            required: "The {{attribute}} is required for a service token",
            flash: {
                created: "Created service token {{name}}",
                updated: "Updated service token {{name}}",
                reactivated: "Reactivated service token {{name}}",
            }
        },
        scimServices: {
            searchPlaceHolder: "Search for SCIM services..",
            noEntities: "There are no service configured with the mock SCIM endpoint",
        }
    },
    user: {
        titleUpdate: "Update your user profile SSH keys",
        ssh_key: "SSH public keys",
        ssh_keys_zero_state: "There are no SSH keys associated with your profile",
        ssh_keyPlaceholder: "Your public SSH keys to log in to services",
        ssh_keyTooltip: "Your public SSH keys will be provisioned to the services you have access to. You can also upload your public SSH key.",
        sshKeyError: "This SSH cipher isn't recognized. Please refer to the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://edu.nl/87btm\">documentation</a> for supported formats.",
        sshConvertInfo: "Public key will be converted to the <code>OpenSSH</code> format when saving the profile",
        update: "Update",
        download: "Download my data",
        flash: {
            updated: "Your profile has been updated"
        },
        providedBy: "Provided by <strong>{{institution}}</strong>",
        generatedBy: "Generated by <strong>SRAM</strong>",
        delete: "Delete my profile",
        deleteConfirmation: "Are you sure you want to delete your profile for all eternity?",
        deleteConfirmationWarning: "All your memberships will be deleted and there is no way to revert this afterwards.",
        deleteConfirmationCheck: "If you wish to proceed, please type in your full name for confirmation.",
        deleteOtherConfirmation: "Are you sure you want to delete the user {{name}} for all eternity?",
        unsuspend: "Undo suspension",
        deleteOther: "Delete",
        unsuspendOtherConfirmation: "Are you sure you want to undo the suspension the user {{name}}?",
        reset2fa: "Reset TOTP",
        reset2faConfirmation: "Are you sure you want to reset the TOTP for user {{name}}?",
    },
    impersonate: {
        title: "Who do you want to be?",
        organisation: "Organisation",
        organisationPlaceholder: "Search and select an organisation to narrow the search result for users...",
        organisationAdminsOnly: "Only show the admins of organisations",
        collaboration: "Collaboration",
        collaborationPlaceholder: "Search and select a collaboration to narrow the search result for users...",
        collaborationAdminsOnly: "Only show the admins of collaborations",
        user: "User",
        userSearchPlaceHolder: "Search and select a user to impersonate...",
        userRequired: "You must first select a user before pretending to be that person",
        currentImpersonation: "Impersonation",
        noImpersonation: "You are who you are - no impersonation",
        currentImpersonationValue: "You are impersonating {{currentUser}}, but you are really {{impersonator}}",
        startImpersonation: "Impersonate",
        clearImpersonation: "Stop impersonating",
        flash: {
            startedImpersonation: "You now impersonate {{name}}.",
            clearedImpersonation: "Cleared your impersonation. You are you again."
        },
        exit: "Stop impersonating",
        impersonator: "You are impersonating <strong>{{name}}</strong> | <strong>{{role}}</strong>",
        impersonatorTooltip: "You are really <em>{{impersonator}}</em>, but you are impersonating <em>{{currentUser}}</em>. On the <strong>Impersonate</strong> page you can change identity or become you again."
    },
    registration: {
        joinRequest: "Request to join {{name}}",
        explanation: "Please fill in your motivation for joining collaboration <strong>{{name}}</strong>.",
        title: "Request membership of {{name}}",
        alreadyMember: "You are already a member of {{name}}",
        motivation: "Your reason",
        motivationPlaceholder: "Describe your reason to join in order for an admin to grant you access.",
        policyConfirmation: "{{collaboration}} has an AUP which you can read (more about) at <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{aup}}\"'>here</a>.",
        feedback: {
            info: "Your request has been sent to the collaboration managers of <strong>{{name}}</strong> who will review your application. Their decision will be communicated to you by email.",
        },
    },
    profile: {
        name: "Name",
        email: "Email",
        uid: "UID",
        affiliation: "Affiliations",
        username: "Username",
        scoped_affiliation: "Scoped affiliations",
        entitlement: "Entitlements",
        nick_name: "Nick name",
        schac_home_organisation: "Organisation domain",
        edu_members: "EDU memberships",
        eduperson_principal_name: "EPPN",
        created_at: "Profile created",
        superUser: "Super User",
        yourRole: "Your role",
        role: "Role",
        member: "Member",
        manager: "Manager",
        admin: "Admin",
        organisation: "Organisation",
        organisations: "Organisations",
        sbs: "Application",
        collaborations: "Collaborations",
        network: "IP ranges",
        addSSHKey: "Upload new SSH key",
        addSSHKeyManually: "Add SSH key manually",
        networkTooltip: "Configure your IP ranges for this service. You can add IPv4 and IPv6 network ranges. " +
            "For example: <ul>" +
            "<li>Single IPv4 address 198.51.100.12 where a /32 is implied</li>" +
            "<li>IPv4 range 198.51.100.0/24. Maximal allowed subnet size for IPv4 is a /24</li>" +
            "<li>IPv6 range 2001:db8:f00f:bab::/128. Maximal allowed subnet size for IPv6 is a /64</li>" +
            "</ul> ",
        networkExplanation: "Inform the service providers from which IP addresses you will be accessing their services. The service providers may allow access to your IP addresses if they support this.",
        deleteHeader: "Delete your profile",
        deleteDisclaimer: "Deleting your profile will also remove all your memberships and admin roles. This cannot be reverted."
    },
    collaborations: {
        dashboard: "Dashboard for your collaborations",
        dashboardAdmin: "Admin dashboard for my collaborations",
        dashboardAdminTooltip: "This is an aggregated view of all collaborations where you are one of the admins.",
        title: "My collaborations",
        noCollaborations: "You are not a member of any collaboration",
        requests: "Join Requests",
        group: "Group",
        groups: "Groups",
        moreInformation: "More information",
        website: "Website",
        invitations: "Invitations",
        services: "Services",
        add: "Create new collaboration",
        searchPlaceHolder: "Search for all collaborations"
    },
    accessTypes: {
        open: "Open",
        closed: "Closed",
        on_acceptance: "On acceptance"
    },
    collaboration: {
        about: "About the collaboration",
        invitations: "Invite admins",
        title: "Create new collaboration",
        requestTitle: "Request a new collaboration",
        requestTitleCreationAllowed: "Request a new collaboration - organisation {{name}} is configured to automatically approve collaborations requests",
        actions: "",
        name: "Name",
        logo: "Collaboration logo",
        useOrganisationLogo: "Use the organisation logo",
        generateLogo: "Generate logo",
        generateLogoTooltip: "You first must enter a Collaboration name to enable logo generation",
        namePlaceHolder: "The unique name of a collaboration",
        shortName: "Short name",
        shortNamePlaceHolder: "Unique short name of the collaboration",
        shortNameTooltip: "Assign short names to collaborations so that these short names can be used in the LDAP services (like Linux group names).<br/><br/>" +
            "Only digits, alphanumeric characters and the underscore are allowed.",
        globalUrn: "Platform identifier",
        globalUrnTooltip: "Platform wide, unique, and read-only identifier based on the short name of the organisation and the collaboration.",
        identifier: "Identifier",
        identifierTooltip: "Generated, unique and immutable identifier of a collaboration which is used as identifier for external systems",
        joinRequestUrlTooltip: "URL for non-members to join this collaboration. You can for instance email this URL or publish it on a website etc.",
        joinRequestUrlDisabled: "Join request are disabled for this collaboration",
        allowJoinRequests: "Allow join requests",
        allowJoinRequestsTooltip: "When checked non-members of this collaboration can issue a join request.",
        joinRequests: "Link for membership request",
        joinRequestsHeader: "Membership request",
        noJoinRequests: "Not allowed",
        memberList: "Member list",
        hidden: "Hidden",
        visible: "Visible",
        disabled: "Disabled",
        enabled: "Copy link",
        aup: "Acceptable use policy",
        noAup: "No acceptable use policy provided",
        expiryDate: "Expiration date",
        expiryDateWarning: {
            one: "Will expire in one day!",
            other: "Will expire in {{nbr}} days!"
        },
        expiryDateExpired: "Expired!",
        lastActivityDate: "Last activity",
        lastActivitySuspended: "Suspended",
        expiryDateTooltip: "The expiration date of the collaboration, after which its members can no longer use its services",
        discloseMembers: "Member list visible for all members",
        discloseNoMembers: "Members cannot view the member list",
        discloseMemberInformation: "Disclose member information",
        discloseMemberInformationTooltip: "When checked the names of members are disclosed to other members",
        discloseEmailInformation: "Disclose email information",
        discloseEmailInformationTooltip: "When checked the emails of members are disclosed to other members",
        description: "Collaboration purpose",
        descriptionPlaceholder: "Provide a clear description of the purpose of the collaboration, such that the processing of personal data can be justified",
        websiteUrl: "Website URL",
        websiteUrlPlaceholder: "The URL of the website is visible for members",
        support_emailPlaceholder: "The email address or the website of the support department for this collaboration",
        supportShort: "Support",
        currentUserAdmin: "Make me admin of this collaboration",
        currentUserAdminTooltip: "If checked you will be added as an admin of this collaboration",
        message: "Optional message",
        messagePlaceholder: "Personal message to the admins",
        messageTooltip: "The message will be included in the email to the admins.",
        motivation: "Motivation",
        motivationPlaceholder: "Motivation for the new collaboration",
        motivationTooltip: "Your motivation for the new collaboration.",
        organisation_name: "Responsible organisation",
        organisationPlaceholder: "Select the organisation for this collaboration...",
        organisationTooltip: "Every collaboration belongs to precisely one and only one organisation",
        tags: "Labels",
        tagsPlaceholder: "Search or create labels",
        tagsTooltip: "A collaboration can have multiple labels. Note that labels do not allow all characters as they can be used as Linux group names.",
        accepted_user_policy: "Acceptable Use Policy",
        acceptedUserPolicyPlaceholder: "The URL of the collaboration specific Acceptable Use Policy",
        role: "Role",
        newTitle: "Create new collaboration",
        subTitle: "Enter / edit the collaboration details. You will become an admin of the new collaboration.",
        alreadyExists: "A collaboration with {{attribute}} {{value}} already exists",
        required: "The {{attribute}} is required for a collaboration",
        administrators: "Admins",
        administratorsPlaceholder: "Invite admins by email",
        administratorsTooltip: "Admins of a collaboration can edit their collaborations and invite members. Add email addresses separated by comma, space or semi-colon or one-by-one using the enter key.",
        members: "Members",
        selectRole: "Select a role...",
        manager: "Manager",
        admin: "Admin",
        member: "Member",
        status: {
            name: "Status",
            active: "Active",
            activeWithExpiryDate: "Expires on {{expiryDate}}",
            suspended: "Suspended",
            expired: "Expired",
            activeTooltip: "This collaboration is active and ready for business.",
            suspendedTooltip: "This collaboration was suspended due to inactivity. It was last used on {{lastActivityDate}}.",
            revertSuspension: " You can revert the suspension with the button below.",
            almostSuspended: "This collaboration will be suspended due to inactivity in {{days}} days.",
            revertAlmostSuspended: " You can prevent the suspension with the button below.",
            expiredTooltip: "This collaboration has reached its expiration date of {{expiryDate}}.",
            activate: "Revert expiration",
            askForExtension: "Request membership extension",
            askForReactivation: "Request membership reactivation",
            askForExtensionSubject: "Request membership extension for user {{email}}",
            askForReactivationSubject: "Request membership reactivation for user {{email}}",
            activeWithExpiryDateTooltip: "This collaboration will expire at {{expiryDate}}. ",
            activeWithExpiryDateAction: "Collaboration settings",
        },
        flash: {
            created: "Collaboration {{name}} was created",
            requested: "Collaboration {{name}} was requested"
        }
    },
    collaborationDetail: {
        title: "Details collaboration {{name}}",
        backToCollaborations: "Back to my collaborations",
        backToHome: "Back to home",
        backToCollaborationDetail: "Back to my collaboration {{name}}",
        update: "Update",
        delete: "Delete",
        deleteConfirmation: "Are you sure you want to delete this collaboration? This cannot be undone.",
        deleteMemberConfirmation: "Are you sure you want to delete the collaboration membership of {{name}}?",
        deleteInvitationConfirmation: "Are you sure you want to delete the invitation for {{name}}?",
        deleteEntitiesConfirmation: "Are you sure you want to delete the checked members and invitations?",
        deleteYourselfMemberConfirmation: "Are you sure you want to leave this collaboration? You will have to be re-invited by an admin to rejoin.",
        noBatchDeleteAllowed: "It is not possible to leave the collaboration as part of a batch delete. Please select only yourself to leave this collaboration, or select only other members to remove others from the group.",
        downgradeYourselfMemberConfirmation: "Are you sure you don't want to be an admin anymore? You won't be able to revert this.",
        activateMemberConfirmation: "Are you sure you want to re-activate user {{name}}? We will send them an email with an activation link.",
        lastAdminWarning: "You are leaving this collaboration without admins. After this, management of the collaboration can only be carried out by the admins of organisation {{name}}",
        lastAdminWarningUser: "You are the last admin in this collaboration. After you leave, management of the collaboration can only be carried out by the admins of organisation {{name}}",
        activateMemberTooltip: "Revert this user's suspension. Suspended users cannot log in",
        expiryDateChange: "Are you sure you want to change the expiry date for {{name}} to {{date}}",
        expiryDateReset: "Are you sure you want to remove the expiry date for {{name}}",
        flash: {
            updated: "Collaboration {{name}} was updated",
            deleted: "Collaboration {{name}} was deleted",
            memberDeleted: "Membership of {{name}} was deleted",
            invitationDeleted: "Invitation for {{name}} was deleted",
            entitiesDeleted: "Membership / invitations are deleted",
            memberUpdated: "The role of membership of {{name}} was updated to {{role}}",
            meAdded: "Added you as member of {{name}}",
            memberActivated: "Member {{name}} has been sent an email with an activation link",
            memberExpiryDateUpdated: "Membership of {{name}} has an updated expiration date {{expiryDate}}"
        },
        infoBlocks: "Dashboard collaboration {{name}}",
        searchPlaceHolder: "Search for members",
        noMembers: "Collaboration {{name}} has no members.",
        members: "Members of {{name}}",
        member: {
            user__name: "Name",
            user__email: "Email",
            user__uid: "UID",
            user__suspended: "Suspended",
            role: "Role",
            created_at: "Since",
            actions: ""
        },
        invite: "Invite new members",
        addMe: "Add me to this collaboration"
    },
    organisations: {
        title: "My services ({{nbr}})",
        noOrganisations: "You are not a member of any organisation",
        members: "Members",
        collaborations: "Collaborations",
        invitations: "Invitations",
        collaborationRequests: "Collaboration requests",
        add: "Create new service",
        searchPlaceHolder: "Search for all organisations",
        deleteConfirmation: "Are you sure you want to delete service {{name}}? This cannot be undone."
    },
    services: {
        title: "Services",
        add: "Create new service",
        searchPlaceHolder: "Search for all services",
        toc: {
            connections: "Connections",
            available: "Available services",
        }
    },
    service: {
        about: "About the service",
        aboutShort: "About",
        logo: "Service logo",
        accessAllowed: "Access allowed",
        compliancy: "Service compliancy",
        compliancyShort: "Compliancy",
        compliancySeparator: "and",
        none: "None",
        contact: "Service contact-person",
        titleNew: "Add service",
        titleRequest: "Register service",
        titleReadOnly: "Service {{name}}",
        backToServices: "Back to services",
        name: "Name",
        namePlaceHolder: "The unique name of the service",
        entity_id: "Entity ID",
        entity_idPlaceHolder: "The unique entity ID of the service",
        entity_idTooltip: "The Entity ID is used as the unique identitfier with SAML (Entity ID) or OIDC (client_id) and uniquely identifies your service at the identity proxy. It is also used for provisioning with LDAP.",
        abbreviation: "Short name",
        abbreviationPlaceHolder: "The short name of this service",
        abbreviationTooltip: "The short name of the service is used as a prefix for groups provisioned by this service's service groups.<br/><br/>" +
            "Only digits, alphanumeric characters and the underscore are allowed.",
        service_request: "Link for service request",
        service_requestTooltip: "The URL to request a connection with the service by a member of a collaboration",
        service_requestError: "Requires a valid URI and collaborations must be allowed to connect without approval",
        description: "Description",
        descriptionPlaceholder: "The description of the service",
        address: "Address",
        addressPlaceholder: "The address of the service",
        identity_type: "Identity type",
        identity_typePlaceholder: "The identity type of the service",
        identity_typeTooltip: "The primary way of identification for this service",
        uri: "Login URL for users",
        uriPlaceholder: "The URL where users log in to the service",
        uriTooltip: "URL where users can log in to your service, displayed prominently to collaboration members.<br/><br/>" +
            "These variables will be replaced with the appropriate values: <em>{CO_short_name}</em> and <em>{username}</em>.",
        infoUri: "Website",
        infoUriPlaceholder: "The URL with information about this service",
        infoUriTooltip: "URL of a website with information about the service",
        privacy_policy: "Privacy policy",
        privacy_policyPlaceholder: "The Private policy URL  of the service",
        privacy_policyTooltip: "A Privacy Policy is a legal requirement for all websites and apps that collect or use personal information from users.",
        accepted_user_policy: "Acceptable use policy",
        accepted_user_policyPlaceholder: "The Acceptable Use Policy (AUP) of the service",
        accepted_user_policyTooltip: "An acceptable use policy (AUP) is a document stipulating constraints and practices that a user must agree to for access to a service or system.",
        network: "LDAP ACL IP ranges",
        networkTooltip: "The IP ranges this service uses to connect to the platform LDAP service. You can add IPv4 and IPv6 network ranges. " +
            "For example: <ul>" +
            "<li>Single IPv4 address 198.51.100.12 where a /32 is implied</li>" +
            "<li>IPv4 range 198.51.100.0/24. Maximal allowed subnet size for IPv4 is a /24</li>" +
            "<li>IPv6 range 2001:db8:f00f:bab::/128. Maximal allowed subnet size for IPv6 is a /64</li>" +
            "</ul> ",
        networkPlaceholder: "IPv4 or IPv6 address with an optional subnet size",
        networkError: "Subnet size {{prefix}} is not allowed. Max prefix allowed for IPv{{version}} is {{max}}",
        networkSyntaxError: "This is not a valid IPv4 or IPv6 address.",
        networkReservedError: "This is a reserved IPv{{version}} address",
        networkNotGlobal: "Only global unicast addresses can be entered",
        networkInfo: "Lower bound IP: {{lower}}, higher bound IP: {{higher}}, # addresses: {{num_addresses}}, version: IPv{{version}}",
        automaticConnectionAllowed: "Collaborations can connect without your approval",
        automaticConnectionAllowedTooltip: "If enabled, a collaboration admin can connect to your service straightaway. No connection request is offered for approval to the service admin (you).",
        automaticConnectionAllowedOrganisations: "Trusted or owned organisations",
        automaticConnectionAllowedOrganisationsTooltip: "If collaborations can not connect without your approval, you can select organisations that you trust or own, and the collaborations of those organisations can connect without your approval.",
        automaticConnectionAllowedOrganisationsPlaceHolder: "Select organisations...",
        accessAllowedForAll: "Available for all organisations",
        accessAllowedForAllTooltip: "Is this service available for all current and future organisations?",
        accessAllowedForAllInfo: "Collaborations of all organisations can connect to this service.",
        nonMemberUsersAccessAllowed: "Accessible without membership of a collaboration.",
        nonMemberUsersAccessAllowedTooltip: "All users can access this service, without collaboration membership.",
        connectCollaborationsInfo: "Collaborations of some organisations can join, and connection settings differ per organisation.",
        noOneAllowed: "No one is allowed to connect a collaboration to this service.",
        viewSettings: "View settings",
        allowRestrictedOrgs: "SURF service",
        allowRestrictedOrgsTooltip: "Allow this service to be linked to collaborations of a SURF organisation.",
        sirtfiCompliant: "Sirtfi compliant?",
        sirtfiCompliantShort: "Sirtfi",
        sirtfiCompliantTooltip: "The Security Incident Response Trust Framework for Federated Identity (Sirtfi) aims to enable the coordination of incident response across federated organisations. This assurance framework comprises a list of assertions which an organisation can attest in order to be declared Sirtfi compliant.",
        codeOfConductCompliant: "CoCo compliant?",
        codeOfConductCompliantShort: "CoCo",
        codeOfConductCompliantTooltip: "The GÉANT Data Protection Code of Conduct (DP-CoCo) is an initiative to meet the requirements of the EU Data Protection Directive for releasing personal attributes from an Identity Provider to a service Provider.",
        researchScholarshipCompliant: "R&S compliant?",
        researchScholarshipCompliantShort: "R&S",
        researchScholarshipCompliantTooltip: "Service Providers compliant to the Research and Scholarship (R&S) Category are service Providers that are operated for the purpose of supporting research and scholarship interaction, collaboration or management, at least in part.",
        allowedOrganisations: "Allowed organisations",
        allowedOrganisationsPlaceholder: "Search and add organisations",
        allowedOrganisationsTooltip: "If any organisation is added then automatically all other organisations are not allowed to use this service. If this service can be used by all collaborations then just don't add organisations.",
        contact_email: "Administrative contact",
        contact_emailPlaceholder: "The email address or website of the administrative department for this service",
        contact_emailTooltip: "This administrative email address will be used as primary contact. Ideally this is a functional email address, like service_A_support@service.example.com.",
        contactEmailRequired: "Administrative email is required if there are no service administrators",
        security_email: "Security contact",
        security_emailPlaceholder: "The email address or website of the security department of this service",
        security_emailTooltip: "The security email address will be contacted if there are security questions. Ideally this is a functional email address, like service_A_security@service.example.com.",
        securityEmailRequired: "Security email is required",
        support_email: "User support contact",
        support_emailPlaceholder: "The email address or the website of the support department for this service",
        support_emailTooltip: "The support email address or URL is shown to the users",
        invitations: "Invite service admins",
        status: {
            name: "Status",
            active: "Active",
            in_active: "In-active"
        },
        statusPlaceholder: "The status of the service",
        alreadyExists: "A service with {{attribute}} {{value}} already exists.",
        required: "The {{attribute}} is required for a service",
        deleteConfirmation: "Are you sure you want to delete service {{name}}? This cannot be undone.",
        requestDeleteConfirmation: "Are you sure you want to send a request to delete service {{name}}? Once your request is honored then this cannot be undone.",
        deleteWarning: "You can not request the deletion of this service. Disconnect the collaborations first at the 'connected collaborations' tab.",
        add: "Create",
        request: "Registration",
        update: "Update",
        delete: "Delete",
        cancel: "Cancel",
        flash: {
            created: "Service {{name}} was created",
            createdServiceRequest: "Thank you for registering service {{name}}. We will look into your request and you'll hear from us the next working day.<br/><br/>The SURF Research Access Management support team",
            updated: "Service {{name}} was updated",
            deleted: "Service {{name}} was deleted",
            requestDeleted: "Request to delete service {{name}} is sent",
            tokenAdded: "New token for {{name}} was created",
            tokenUpdated: "Token of {{name}} was updated",
            tokenDeleted: "Token of {{name}} was deleted",
        },
        scim_token: {
            preTitle: "For security reasons, the current SCIM token can not be displayed. ",
            title: "Change the SCIM token.",
            confirmation: "Please enter the SCIM token for {{name}}. It will be stored encrypted.",
            success: "SCIM token has been updated.",
        },
        ldap: {
            preTitle: "For security reasons, the current password can not be displayed. ",
            title: "Reset the LDAP password.",
            confirmation: "Are you sure you want to reset the LDAP password for {{name}}?",
            success: "LDAP password has been reset.",
            info: "Copy and store the password somewhere safe. You can not see this password afterwards.",
            copy: "Copy",
            close: "Close",
            section: "LDAP settings",
            sectionTooltip: "The settings of the SRAM platform LDAP server to synchronize with the LDAP server of the service.",
            url: "LDAP URL",
            urlTooltip: "The URL of the platform LDAP server. Enter this on the service, or your LDAP server to synchronize.",
            username: "Bind DN of the platform LDAP server",
            usernameTooltip: "The bind DN used to authenticate to the platform LDAP server",
            basedn: "Base DN",
            basednTooltip: "The base DN of the platform LDAP server",
            ldapClient: "LDAP client",
            ldapEnabledTooltip: "Allow this service to query the SRAM LDAP server",
            ldapDisclaimer: "LDAP is disabled",
        },
        aup: {
            title: "Reset AUP",
            confirmation: "Resetting the AUP will require all user of this service '{{name}}' to accept its AUP again. Do you want that?",
            flash: "Acceptance of the AUP for service {{name}} has been reset for all users"
        },
        pamWebSSO: {
            pamWebSSODisclaimer: "PAM web login is disabled"
        },
        contacts: "Contacts",
        leave: "Leave service",
        confirmation: {
            leave: "Are you sure you don't want to be the admin of this service? You can not undo this.",
            lastAdminWarning: "You are leaving this service without admins. After this, management of the service can only be carried out by the admins of SRAM",
            lastAdminWarningUser: "You are the last admin in this service. After you leave, management of the service can only be carried out by the admins of SRAM",
        },
        viewAsServiceAdmin: "View as service admin",
        viewAsPlatformAdmin: "View as platform admin",
        fakeServiceAdmin: "You're service admin (not)",
        addMe: "Make me service admin",
        access: "Access",
        policies: "Policies",
        policiesSupport: "Policies & Support",
        tokens: "My Tokens",
        policyCompliance: "Compliant policies",
        login: "Log in",
        support: "Support for end user",
        supportShort: "Support",
        supportContactPre: "For support please contact ",
        or: " or ",
        visitWebsite: "visit the website",
        supportThroughWebsitePre: "For support ",
        supportThroughWebsiteLink: "visit the website.",
        admins: "Administrative email address",
        adminContact: "Administrative contact",
        noSupport: "Service {{name}} does not provide support for end users.",
        launch: "Open",
        connectionDetails: "Connection details",
        contactSupport: "Contact & Support",
        providingOrganisation: "Providing organisation",
        providingOrganisationPlaceholder: "Name of the providing organisation",
        protocol: "Web based protocol",
        protocols: {
            openIDConnect: "OpenID Connect",
            saml2URL: "SAML2 URL",
            saml2File: "SAML2 file",
            none: "None, this service has no web interface"
        },
        protocolsShort: {
            openIDConnect: "OIDC",
            saml2URL: "SAML2",
            saml2File: "SAML2",
            none: "None"
        },
        openIDConnectRedirects: "OpenID Connect redirect URLs",
        openIDConnectRedirectsPlaceholder: "https://service.com/redirect",
        openIDConnectRedirectsTooltip: "The redirect URLs after the user authentication is successfully done. Must be a full match, but you can enter multiple URLs.",
        samlMetadata: "SAML metadata",
        samlMetadataURL: "SAML metadata URL",
        samlMetadataPlaceholder: "https://metadata",
        samlMetadataUpload: "Upload SAML metadata",
        noneInfo: "Other protocols can be selected later",
        commentsTooltip: "Your comments will be visible for the administrators that evaluate your service request",
        comments: "Optional comments",
        commentsPlaceholder: "Comments about this request   ",
        commentsHeader: "Additional comments about this request",
        commentsAttribute: "comments",
        connectionSettings: {
            connectQuestion: "1. Who can log in to the service?",
            coMembers: "Connected collaborations",
            institutionSelection: "I'll select who may connect below",
            none: "None",
            noneText: "Collaborations cannot connect",
            noOne: "No one",
            later: "I'll set this up later",
            everyOne: "Everyone",
            everyOneText: "No membership required",
            whichInstitutionsQuestion: "2. Collaborations from which organisations may connect?",
            allInstitutions: "All organisations",
            allCOWelcome: "All collaborations are welcome",
            onlySome: "Only some",
            specificInstitutions: "I'll choose which organisations below",
            directlyConnectQuestion: "3. Can a collaboration connect straight away?",
            directConnect: "Collaborations can connect straight away",
            manuallyApprove: "I want to approve requests manually",
            depends: "It depends",
            settingsPerInstitution: "I'll choose for each organisation below"
        },
        sweep: {
            test: "Test SCIM",
            testTooltip: "Test the SCIM endpoint and the SCIM token",
            success: "The SCIM connection test to {{url}} was successful",
            failure: "The SCIM connection test to {{url}} was unsuccessful.",
            response: "Response from the SCIM endpoint:",
            saveBeforeTest: "Update service",
            saveBeforeTestQuestion: "You have made changes to the SCIM configuration. Do you want to save this changes before you test the SCIM endpoint?"
        },
        openIDConnectGrants: "OpenID Connect grants",
        openIDConnectGrantsPlaceholder: "Choose grant(s)",
        openIDConnectGrantsTooltip: "Grant types are the way to specify how a Relying Party will interact with the OIDC server",
        isPublicClient: "Public client",
        isPublicClientTooltip: "Public clients are not required to authenticate, but must use the Proof key for Code Exchange (PKCE) authorisation",
        grants: {
            authorization_code: "Authorization code",
            implicit: "Implicit (deprecated)",
            refresh_token: "Refresh token",
            client_credentials: "Client credentials",
            device_code: "Device code"
        },
        samlMetaData: {
            acs_binding: "ACSBinding",
            acs_location: "ACSLocation",
            entity_id: "EntityID",
            organization_name: "Organization",
            unknown: "Not present",
            missingAttributes: "Incomplete SAML meta data"
        },
        oidc: {
            oidcClientSecretDisclaimer: "You can view this OIDC client secret only once. Copy it and store it somewhere safe. If the secret is lost, then you'll have to request a new one.",
            oidcClientSecret: "OpenID client secret",
            oidcClientSecretTooltip: "The secret to use when requesting a OIDC token",
            preTitle: "For security reasons, the current OIDC client secret can not be displayed. ",
            title: "Reset the OIDC client secret.",
            confirmation: "Are you sure you want to reset the OIDC client secret for {{name}}?",
            success: "OIDC client secret has been reset.",
            info: "Copy and store the secret somewhere safe. You can not see this secret afterwards.",
            copy: "Copy",
            close: "Close",
            section: "OIDC settings",
            sectionTooltip: "The settings of the OIDC properties to enable OIDC login with this service.",
            oidcClient: "OIDC protocol",
            oidcEnabledTooltip: "Allow this service to login with the OIDC protocol",
            oidcDisclaimer: "OIDC protocol is disabled",
            oidcDisabledExclusivity: "OIDC protocol can not be enabled, because the SAML protocol is active"
        },
        saml: {
            section: "SAML settings",
            sectionTooltip: "The SAML protocol settings to enable SAML login with this service.",
            samlClient: "SAML protocol",
            samlEnabledTooltip: "Allow this service to login with the SAML protocol",
            samlDisclaimer: "SAML protocol is disabled",
            samlError: "Either the SAML metadata URL or a SAML metadata file are required",
            samlDisabledExclusivity: "SAML protocol can not be enabled, because the OIDC protocol is active"
        },
        export: {
            exportDisabled: "This service will not be externally synced, as this feature is disabled in this environment.",
            noExport: "This service will not be externally synced, as OIDC and SAML are both disabled.",
            export: "This service will be externally synced after changes, as OIDC or SAML is enabled.",
            lastExportDate: "Last synced on date",
            notExported: "Not exported yet",
            lastExportStatus: "Last sync status",
            successful: "Successfully synced",
            failure: "Sync failure",
            externalIdentifier: "External identifier",
            externalVersion: "External version",
            externalLink: "External link",
            externalLinkValue: "{{base_url}}/metadata/sram/{{external_identifier}}"
        }
    },
    organisation: {
        title: "Create new organisation",
        subTitle: "Enter / edit the organisation details.",
        actions: "",
        about: "About the organisation",
        name: "Name",
        namePlaceHolder: "The unique name of an organisation",
        invitations: "Invite organisation admins",
        category: "Category",
        categoryTooltip: "Type of institution",
        tenantPlaceHolder: "The unique tenant / organisation identifier linking the organisation to an institute",
        shortName: "Short name",
        shortNamePlaceHolder: "Short name of the organisation",
        shortNameTooltip: "The short name is used in LDAP services (like Linux directory names).<br/><br/>" +
            "Only digits, alphanumeric characters and the underscore are allowed.",
        identifier: "Identifier",
        identifierTooltip: "Generated, unique and immutable identifier of a organisation which used as identifier for external systems",
        description: "Description",
        descriptionPlaceholder: "The description of the organisation is visible to anyone",
        servicesRestricted: "SURF organisation",
        servicesRestrictedTooltip: "Collaborations from SURF organisation can only link SURF services.",
        schacHomeOrganisationShortName: "Organisation domain",
        schacHomeOrganisationShortNames: "Organisation domains",
        schacHomeOrganisation: "Organisation domain names",
        schacHomeOrganisationPlaceholder: "The domain names of the organisation",
        schacHomeOrganisationTooltip: "The domain names users of this organisation log in with. These users can request or create collaborations with your organisation.",
        collaborationCreationAllowed: "Users can create collaborations straightaway",
        collaborationCreationAllowedTooltip: "Allows users from your organisation to create a collaboration without requiring approval from an organisation admin or manager",
        serviceConnectionRequiresApproval: "Service connection request must be approved by the Organisation admin",
        serviceConnectionRequiresApprovalTooltip: "If checked then service connection requests must first be approved by an Organisation admin",
        accepted_user_policyPlaceholder: "The Acceptable Use Policy (AUP) of the organisation",
        accepted_user_policyTooltip: "Users outside your organisation must accept this AUP before joining one of this organisation's collaboration for the first time.",
        crmId: "CRM ID",
        crmIdPlaceholder: "CRM identifier, e.g. A80A11E8-1998-4206-9F83-24ECA6336615",
        collaborationCreationLabel: "Users from these domains",
        collaborationCreationIsAllowed: "Can create collaborations",
        collaborationCreationNotAllowed: "Can request collaborations",
        singleSchacHome: "this domain",
        multipleSchacHome: "these domains",
        invalidSchacHome: "{{schac}} is not a valid domain name",
        duplicateSchacHome: "Organisation domain names must be unique",
        created: "Created at",
        message: "Optional message",
        messagePlaceholder: "Personal message to the admins",
        messageTooltip: "The message will be included in the email invitation to the admins.",
        alreadyExists: "An organisation with {{attribute}} {{value}} already exists.",
        required: "The {{attribute}} is required for an organisation",
        administrators: "Admins",
        administratorsPlaceholder: "Invite admins by email",
        filePlaceholder: "Select csv or txt file...",
        fileImportResult: "Imported {{nbr}} email addresses from {{fileName}}",
        fileExtensionError: "Only .csv extension files are allowed",
        administratorsTooltip: "Admins of an organisation can create collaborations in their organisations. Add email addresses separated by comma, space or semi-colon or one-by-one using the enter key.",
        role: "Role",
        new: "Create new service",
        admin: "Admin",
        manager: "Manager",
        member: "Member",
        organisationRoles: {
            admin: "Organisation Admin",
            manager: "Organisation Manager",
            undefined: ""
        },
        organisationShortRoles: {
            admin: "Admin",
            manager: "Manager",
            undefined: ""
        },
        yourself: "{{name}} (it's you)",
        logo: "Organisation logo",
        deleteConfirmation: "Are you sure you want to delete this organisation? This cannot be undone.",
        flash: {
            created: "Organisation {{name}} was created"
        },
        onBoarding: {
            label: "Message for users requesting or creating a collaboration",
            invalid: "You must change the message to users",
            tooltip: "This message is shown to users from your organisation when they request or create a collaboration",
            template: "Hi!,\n\n" +
                "You can **request/create** a collaboration to get started. Please describe why you want to create this collaboration. We might contact you for further details.",
            tabs: {
                write: "Markdown",
                preview: "Preview"
            }
        }
    },
    organisationDetail: {
        backToOrganisations: "Back to my organisations",
        backToOrganisationDetail: "Back to my organisation {{name}}",
        title: "Details organisation {{name}}",
        back: "Back to my organisations",
        members: "Members of {{name}}",
        noMembers: "This organisation has no members.",
        mailMembers: "Email all members",
        mailMembersTooltip: "Email all members of this organisation",
        invitations: "Invitations for {{name}}",
        collaborationRequests: "Requests for collaborations for {{name}}",
        searchPlaceHolder: "Search for members",
        invite: "Invite new members",
        newApiKey: "Add organisation API token",
        noInvitations: "No pending invitations",
        noCollaborationRequests: "No pending collaboration requests",
        member: {
            user__name: "Name",
            user__email: "Email",
            user__uid: "UID",
            user__suspended: "Suspended",
            role: "Role",
            created_at: "Since",
            actions: ""
        },
        invitation: {
            actions: "",
            invitee_email: "Invitee email",
            user__name: "Invited by",
            intended_role: "Role",
            expiry_date: "Expires",
            noExpires: "N/A",
            message: "Message",
        },
        collaborationRequest: {
            actions: "",
            name: "Name",
            short_name: "Short name",
            requester__name: "Requester",
            message: "Motivation"
        },
        collaboration: {
            name: "Name",
            description: "Description",
            short_name: "Short name",
            global_urn: "Platform identifier",
            accepted_user_policy: "AUP",
            created_at: "Since",
            actions: "",
            link: ""
        },
        service: {
            name: "Name",
            entity_id: "Entity ID",
            abbreviation: "Short name",
            description: "Description",
            actions: "",
        },
        apiKeys: "Organisation API tokens of {{name}}",
        collaborations: "Collaborations of {{name}}",
        noCollaborations: "This organisation has no collaborations.",
        newCollaboration: "Create new collaboration",
        services: "Services connected to {{name}}",
        noServices: "There are no default services for this organisation",
        configureServices: "Configure services",
        searchPlaceHolderCollaborations: "Search for collaborations",
        update: "Update",
        delete: "Delete",
        deleteMemberConfirmation: "Are you sure you want to delete these organisation admins and managers?",
        deleteSingleMemberConfirmation: "Are you sure you want to delete this admin?",
        deleteSingleInvitationConfirmation: "Are you sure you want to delete this invitation?",
        deleteYourselfMemberConfirmation: "Are you sure you want to leave this organisation? You won't be able to revert this.",
        deleteApiKeyConfirmation: "Are you sure you want to delete this organisation API token?",
        deleteCollaborationConfirmation: "Are you sure you want to delete collaboration {{name}}? This cannot be undone.",
        activateMemberConfirmation: "Are you sure you want to re-activate user {{name}}? We will send them an email with an activation link.",
        flash: {
            updated: "Organisation {{name}} was updated.",
            deleted: "Organisation {{name}} was deleted.",
            entitiesDeleted: "Membership / invitations are deleted.",
            memberDeleted: "Membership of {{name}} was deleted.",
            apiKeyDeleted: "Organisation API token was deleted.",
            collaborationDeleted: "collaboration {{name}} was deleted.",
            memberActivated: "Member {{name}} has been sent an email with an activation link.",
            memberUpdated: "The role of membership of {{name}} was updated to {{role}}.",
        },
        preview: "Preview",
        details: "Details",
        gone: {
            member: "This membership does no longer exists. After closing this popup the memberships will be refreshed.",
            invitation: "This invitation has already been accepted / rejected and does no longer exists. After closing this popup the invitations will be refreshed."
        }
    },
    joinRequest: {
        title: "Join request from {{requester}} for collaboration {{collaboration}}",
        message: "Motivation",
        messageTooltip: "The motivation from {{name}} for this join request",
        collaborationName: "Collaboration",
        userName: "User",
        decline: "Decline",
        accept: "Accept",
        declineConfirmation: "Are you sure you want to decline this join request?",
        deleteConfirmation: "Are you sure you want to delete this join request?",
        rejectionReason: "Please enter the reason to decline this join request",
        rejectionReasonLabel: "Reason for declination",
        rejectionReasonNote: "Note that the reason is included in the email to the requester",
        why: "Why do you want to join this collaboration?",
        invited: "I was invited to apply",
        projectMember: "I am a member of this project",
        other: "Other, please explain",
        flash: {
            declined: "Join request for collaboration {{name}} was denied.",
            accepted: "Join request for collaboration {{name}} was accepted.",
            deleted: "Join request for collaboration {{name}} was deleted.",
            notFound: "This join request has already been accepted / denied.",
            alreadyMember: "You are already a member of collaboration {{name}} and can therefore not accept this invitation"
        }
    },
    organisationInvitation: {
        title: "Invitation to join organisation {{organisation}}",
        backToOrganisationDetail: "Back to my organisation {{name}}",
        createTitle: "Invite new members to join organisation {{organisation}}",
        organisationName: "Name",
        organisationDescription: "Description",
        organisationAdministrators: "Admins",
        requiredAdministrator: "At least one admin email address is required for an invitation for an organisation",
        role: "Intended role",
        expiryDate: "Invitation valid until",
        expiryDateTooltip: "The expiration date of the invitation, after which the invitation cannot be accepted",
        message: "Message",
        messageTooltip: "The user {{name}} has invited you with this message",
        fileImportResult: "Imported {{nbr}} email addresses from {{fileName}}",
        fileExtensionError: "Only .csv extension files are allowed",
        inviter: "Inviter",
        decline: "Decline",
        accept: "Accept",
        invite: "Invite",
        delete: "Delete",
        resend: "Resend",
        declineInvitation: "Are you sure you want to decline this invitation?",
        deleteInvitation: "Are you sure you want to delete this invitation?",
        resendInvitation: "Are you sure you want to resend this invitation?",
        resendInvitations: "Are you sure you want to resend all selected invitation?",
        expired: "This invitation expired on {{expiry_date}} and cannot be accepted anymore.",
        expiredAdmin: "This invitation expired on {{expiry_date}}. Resend the invitation to reset the expiration date to 14 days.",
        flash: {
            inviteDeclined: "Invitation for organisation {{name}} was declined.",
            inviteDeleted: "Invitation for organisation {{name}} was deleted.",
            inviteResend: "Invitation for organisation {{name}} was resent.",
            invitesResend: "Invitations for organisation {{name}} were resent.",
            inviteAccepted: "Invitation for organisation {{name}} was accepted.",
            created: "Invitions for organisation {{name}} are created.",
            alreadyMember: "The invitation could not be accepted because you are already a member of this organisation.",
            notFound: "Invitation has already been accepted / declined. If you think there is a problem check with your admin (or with support at sram-support@surf.nl)."
        },
    },
    apiKeys: {
        title: "Create an organisation API token for {{organisation}}",
        info: "An organisation API token is required to use the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{base_url}}/apidocs/\"'>Application Programmer Interface (API)</a>.",
        backToOrganisationDetail: "Back to my organisation {{name}}",
        secretDisclaimer: "You can view this organisation API token only once. Copy it and store it somewhere safe.<br><br>If the token is lost, delete it and create a new one.",
        secret: "Organisation API token",
        secretValue: "One-way hashed secret",
        secretTooltip: "The secret to use in the authorization header",
        description: "Description",
        descriptionPlaceHolder: "Description for this organisation API token",
        descriptionTooltip: "An optional description explaining the use of this organisation API token",
        flash: {
            created: "Organisation API token for {{name}} created.",
        },
        submit: "Submit",
        required: "The {{attribute}} is required for an API token",
    },
    invitation: {
        title: "Invitation to join collaboration {{collaboration}}",
        createTitle: "Send invitations to join collaboration {{collaboration}}",
        collaborationName: "Name",
        collaborationDescription: "Description",
        collaborationAdministrators: "Admins",
        invitees: "Invitees",
        inviteesPlaceholder: "Invitee email addresses",
        inviteesTooltip: "This personal message is included in the email sent to the persons you invite",
        intendedRole: "Role",
        intendedRoleTooltip: "The permissions granted to all invitees. Admins can edit the collaboration, connect services and invite members.",
        intendedRoleOrganisation: "Role",
        intendedRoleTooltipOrganisation: "The permissions granted to all invitees. Organisation admins can edit the organisation and invite admins and managers. Managers can only manage collaborations",
        invitee_email: "Invitee email",
        groupsPlaceHolder: "Select groups",
        groupsTooltip: "Select the groups the invitees become member of.",
        groups: "Group membership",
        requiredEmail: "At least one email address is required for an invitation for a collaboration.",
        existingInvitation: "There is already an outstanding invitation for: {{emails}}",
        existingInvitations: "There are already outstanding invitations for: {{emails}}",
        requiredRole: "You must choose the intended role for the collaboration membership.",
        requiredExpiryDate: "The expiry date for an invitation is required",
        message: "Optional message",
        messagePlaceholder: "Personal message to the admins",
        messageTooltip: "The message will be included in the email invitation to the admins.",
        inviteesMessagesTooltip: "Add email addresses separated by comma, space or semi-colon or one-by-one using the enter key. You can also copy & paste a csv file with line-separated email addresses.",
        invalidEmails: "Invalid email addresses removed: {{emails}}.",
        appendAdminNote: "Note: the invitees will become <strong>admin</strong> after accepting the invite.",
        inviteesMessagePlaceholder: "Personal message to the invitees",
        inviter: "Inviter",
        decline: "Decline",
        accept: "Accept",
        delete: "Delete",
        resend: "Resend",
        invite: "Invite",
        declineInvitation: "Are you sure you want to decline this invitation?",
        deleteInvitation: "Are you sure you want to delete this invitation?",
        resendInvitation: "Are you sure you want to resend this invitation?",
        expired: "This invitation expired on {{expiry_date}} and cannot be accepted anymore.",
        expiredAdmin: "This invitation expired on {{expiry_date}}. Resend the invitation to reset the expiration date to 14 days.",
        filePlaceholder: "Select csv or txt file...",
        fileImportResult: "Imported {{nbr}} email addresses from {{fileName}}",
        fileExtensionError: "Only .csv extension files are allowed",
        expiryDate: "Invitation expires on",
        expiryDateTooltip: "The expiration date of the invitation, after which the invitation can no longer be accepted.",
        membershipExpiryDate: "Membership valid until",
        membershipExpiryDateTooltip: "The expiration date of the membership, after which the user cannot log in to services connected to this collaboration.",
        flash: {
            inviteDeclined: "Invitation for collaboration {{name}} was declined.",
            inviteAccepted: "Invitation for collaboration {{name}} was accepted.",
            inviteDeleted: "Invitation for collaboration {{name}} was deleted.",
            inviteResend: "Invitation for collaboration {{name}} was resent.",
            created: "Invitations for collaboration {{name}} were created.",
            alreadyMember: "The invitation could not be accepted because you are already a member of this collaboration.",
        },
    },
    collaborationServices: {
        title: "Services for collaboration {{name}}",
        connectedServices: "Connected services to {{name}}",
        noServices: "This collaboration has no services connected.",
        noServiceConnectRequests: "This collaboration has no outstanding service Connect Requests.",
        searchServices: "Search, select and add services to the available services within collaboration {{name}}",
        deleteServiceTooltip: "Make this service unavailable in the collaboration {{name}}. <strong>Note</strong>: the service itself is <strong>not</strong> deleted. It is only not available anymore for groups within this collaboration.",
        notAllowedOrganisation: "Service '{{service}}' cannot be added to collaboration '{{collaboration}}'. The service configuration excludes it from organisation '{{organisation}}'.",
        automaticConnectionNotAllowed: "Service '{{service}}' cannot be automatically added to collaboration '{{collaboration}}'. The service configuration dictates that a service connect request must be submitted. Please add your motivation and submit this form.",
        motivation: "Motivation",
        motivationPlaceholder: "Your motivation to request a service connection",
        send: "Send",
        flash: {
            added: "Added service {{service}} to collaboration {{name}}.",
            deleted: "Deleted service {{service}} from collaboration {{name}}.",
            send: "Sent service connection request for {{service}}.",
        },
        service: {
            open: "",
            actions: "",
            name: "Name",
            entity_id: "Entity ID",
            abbreviation: "Short name",
            description: "Description"
        },
        serviceConnectionRequest: {
            actions: "",
            resend: "",
            service: "Service",
            requester: "Requester",
            created_at: "Created",
            message: "Message"
        },
        actionsTooltip: "Delete the service Connection request. The request can henceforth not be approved or denied anymore.",
        serviceConnectionRequests: "Service Connection Requests",
        serviceConnectionRequestDeleted: "Deleted request to connect service {{service}} to collaboration {{collaboration}}.",
        serviceConnectionRequestResend: "Resent request to connect service {{service}} to collaboration {{collaboration}}.",
        serviceRestrictedInfo: "This collaboration is service restricted. Only the platform admin can configure services to be used by this collaboration.",
        serviceDeleteConfirmation: "Are you sure you want to remove this service from collaboration {{collaboration}}?",
        serviceConnectionRequestDeleteConfirmation: "Are you sure you want to retract this service connection request?",
        serviceConnectionRequestResendConfirmation: "Are you sure you want to resend this service connection request?",
    },
    organisationServices: {
        title: "Services for service {{name}}",
        connectedServices: "Connected services to {{name}}",
        noServices: "This organisation has no services connected.",
        searchServices: "Search, select and add services to the available services within organisation {{name}}.",
        deleteServiceTooltip: "Make this service unavailable in the organisation {{name}}. <strong>Note</strong>: the service itself is NOT deleted. It is only not available anymore for the members of the collaborations on this organisation",
        noAccessAllowed: "This service cannot be enabled for all collaborations, because none collaborations can connect.",
        notAllowedOrganisation: "This service cannot be enabled for all collaborations, because it does not allow automatic connections. Connections can still be requested by individual collaborations in your organisation.",
        notEnabledOrganisation: "This service cannot be enabled for all collaborations, because the service does not allow access for this organisation.",
        serviceRestrictedOrganisation: "This service cannot be made mandatory for all collaborations, because this organisation can only link SURF services.",
        flash: {
            added: "Added service {{service}} to organisation {{name}}",
            deleted: "Deleted service {{service}} from organisation {{name}}"
        },
        service: {
            open: "",
            actions: "",
            name: "Name",
            entity_id: "Entity ID",
            description: "Description"
        },
        serviceDeleteConfirmation: "Are you sure you want to remove this service from organisation {{organisation}}",
    },
    groups: {
        title: "Groups within collaboration {{name}}",
        servicesTitle: "Services for group {{name}}",
        membersTitle: "Members of group {{name}}",
        membersSubTitle: "Members of this group",
        noMembers: "This group has no members.",
        invitationsTitle: "Pending invitations to become members of this group",
        pendingInvite: "Pending invite",
        noInvites: "This group has no pending invites.",
        titleNew: "Create new group",
        titleUpdate: "Update group {{name}}",
        titleReadOnly: "Group {{name}}",
        backToCollaborationGroups: "Back to the groups of my collaboration {{name}}",
        new: "Create new Group",
        searchPlaceHolder: "Search for groups",
        name: "Name",
        namePlaceholder: "Name of the group",
        short_name: "Short name",
        shortNamePlaceHolder: "Short name of the group",
        shortNameTooltip: "A service group's short name will be used (1) for groups provisioned to collaborations, prepended by short name of the service and separated by a hyphen '-' and (2) in the challenge URL generated by PAM web login..<br/><br/>" +
            "Only digits, alphanumeric characters and the underscore are allowed.",
        identifier: "Identifier",
        identifierTooltip: "Generated, unique and immutable identifier of a group which is used as identifier for external systems",
        collaboration: "Collaboration",
        autoProvisionMembers: "Auto-provision collaboration members",
        autoProvisionMembersTooltip: "Check to automatically add all existing and future new collaboration members to this group",
        global_urn: "Platform identifier",
        globalUrnTooltip: "Platform wide, unique, and read-only identifier based on the short name of the organisation, the collaboration and the group.",
        alreadyExists: "A group with {{attribute}} {{value}} already exists.",
        required: "{{attribute}} is a required field for a group",
        uri: "URI",
        uriPlaceholder: "URI of the group",
        description: "Description",
        descriptionPlaceholder: "Description of the group",
        status: "Status",
        statusPlaceholder: "The status of the group",
        actions: "",
        open: "",
        deleteConfirmation: "Are you sure you want to delete group {{name}}?",
        removeServiceConfirmation: "Are you sure you want to remove service {{name}} from this group?",
        removeServiceConfirmationDetails: "The following service specific information will be deleted:",
        removeMemberConfirmation: "Are you sure you want to remove member {{name}} from this group?",
        removeMemberConfirmationDetails: "The following information for this user will be deleted:",
        user: "User {{name}}",
        attributes: "Attributes",
        statusValues: {
            active: "Active",
            in_active: "In-active"
        },
        add: "Create",
        update: "Update",
        delete: "Delete",
        cancel: "Cancel",
        flash: {
            created: "Group {{name}} was created",
            updated: "Group {{name}} was updated",
            deleted: "Deleted group {{name}}",
            deletedGroups: "Deleted groups",
            addedService: "Added service {{service}} to group {{name}}",
            deletedService: "Deleted service {{service}} from group {{name}}",
            addedServices: "Added all services to group {{name}}",
            addedMember: "Added user {{member}} as a member of group {{name}}",
            addedMembers: "Added all users and invitations as (future ) members of group {{name}}",
            deletedMember: "Deleted user {{member}} from group {{name}}",
            deletedMembers: "Deleted members from group {{name}}",
            addedInvitation: "Added user {{member}} as a member of group {{name}}",
            deletedInvitation: "Deleted invitee {{invitation}} from group {{name}}",
        },
        addAllMembers: "Add all collaboration members and outstanding invitees to this group",
        addAllServices: "Add all collaboration services to this group",
        searchServices: "Search, select and add services to the available services for the group {{name}}",
        connectedServices: "Connected services to {{name}}",
        deleteServiceWarning: "Warning: Unlinking services from the group deletes all user information specific for that service and all linked memberships",
        deleteServiceTooltip: "Make this service unavailable in the group {{name}} <strong>Note</strong>: the service itself is <strong>not</strong> deleted. It is only no longer available for this group",
        searchMembers: "Search, select and add members to the group {{name}}",
        connectedMembers: "Members of {{name}}",
        deleteMemberWarning: "Warning: Unlinking memberships from the group deletes all user information specific for that membership and all linked services",
        deleteMemberTooltip: "Remove this member from the group {{name}} <strong>Note</strong>: the user itself is NOT deleted. They are only no longer a member of this groups",
        deleteInvitationTooltip: "Remove this invitation from the group {{name}} <strong>Note</strong>: the invitation itself is NOT deleted. They will not be added as a member of this group when the invitation is accepted",
        service: {
            actions: "",
            name: "Name",
            entity_id: "Entity ID",
            description: "Description"
        },
        member: {
            user__name: "Name",
            user__email: "Email",
            user__uid: "UID",
            role: "Role",
            created_at: "Since",
            actions: ""
        },
        invitation: {
            invitee_email: "Email",
            intended_role: "Role",
            expiry_date: "Expiration date",
            actions: ""
        },
        manageMembers: "Manage members"
    },
    aup: {
        hi: "Hi {{name}},",
        name: "Display name.",
        email: "Email address",
        title: "Acceptable use policy",
        multiple: "Service acceptable use policies",
        disclaimer: "This is the first time you log in. To create your profile, you need to agree with the <a href=\"{{url}}\" target=\"_blank\">acceptable use policy</a>.",
        agreeWithTerms: "I hereby certify that I have read the acceptable use policy and that I accept it",
        onward: "Onwards",
        agreedFlash: "Your agreement with {{name}} has been saved.",
        collaboration: {
            title: "Additional terms to the Acceptable use policy",
            info: "For this collaboration, there are some additions to the general SURF Research Access Management acceptable use policy. You need to accept these additions to be able to join this collaboration.",
            agreeWithTerms: "I have read these terms and accept them",
        },
        service: {
            title: "Review the information in order to continue",
            info: "You are about to log in to <strong>{{name}}</strong>. Before you can continue you must review the acceptable use policy (AUP) and privacy policy of the service. Confirm below whether you accept them.",
            noPrivacyPolicy: "No privacy policy provided",
            noAup: "No AUP provided",
            noContact: "No contact provided",
            agreeWithTerms: "I hereby certify that I have read these terms and that I accept them",
            firstLogin: "Proceed to log in and afterwards you'll return here to view the policies of {{name}}",
            login: "Log in",
            proceed: "Proceed to {{name}}",
            multipleCollaborations: "This service is used within multiple collaborations you are a member of.",
            purposeOf: "Purpose of <strong>{{name}}</strong>",
            informationService: "Information about the service",
            informationServiceMultiple: "Information about the services",
            contact: "Contact",
            organisationAccess: "You can access this service because it has been made available to everyone in your organisation by the organisation administrators.",
            missing: {
                info: "The service acceptable use policy (AUP) has changed. Please read it and indicate below whether you accept.",
                infoMultiple: "These service acceptable use policies (AUPs) have changed. Please read them and indicate below whether you accept."
            },
            singleInfo: "A service used by this collaboration requires that you agree to their acceptable use policy.",
            multipleInfo: "Services used by this collaboration require that you agree to their acceptable use policies.",
            singleCheck: "I agree to the service's acceptable use policy",
            multipleCheck: "I agree to the services' acceptable use policies"
        },
        organisation: {
            title: "Organisation AUP",
            info: "You are not a member of organisation <strong>{{name}}</strong>. Before you can continue you must review the AUP of this organisation. Confirm below whether you accept it.",
            check: "I agree to the organisation acceptable use policy"
        }
    },
    collaborationRequest: {
        title: {
            open: "{{requester}} has requested to create a new collaboration '{{name}}'.",
            approved: "{{requester}} collaboration request was approved.",
            denied: "{{requester}} collaboration request was denied."
        },
        request: "Request {{id}}",
        requester: "Requester",
        approved: "Approved",
        rejectionReason: "Denied reason",
        denyConfirmation: "Are you sure you want to deny this collaboration request?",
        deleteConfirmation: "Are you sure you want to delete this collaboration request?",
        approve: "Approve",
        deny: "Deny",
        status: "Status",
        statuses: {
            all: "All ({{nbr}})",
            open: "Open",
            denied: "Denied",
            approved: "Approved"
        },
        flash: {
            approved: "Collaboration {{name}} has been created and the collaboration Request has been deleted",
            denied: "Collaboration Request for {{name}} has been denied",
            deleted: "Collaboration Request for {{name}} has been deleted",
        }
    },
    serviceConnectionRequest: {
        title: "Service Connection request",
        subTitle: "Request from {{requester}} to allow access for collaboration {{collaboration}} to service {{service}}",
        message: " Motivation",
        messageTooltip: "The motivation from {{name}} for this request",
        service: "Service",
        collaboration: "Collaboration",
        requester: "Requester",
        decline: "Decline",
        accept: "Accept",
        retract: "Retract",
        approveConfirmation: "Are you sure you want to approve this request?",
        declineConfirmation: "Are you sure you want to decline this request?",
        deleteConfirmation: "Are you sure you want to delete this request?",
        flash: {
            declined: "Service Connect request for service {{name}} was declined",
            accepted: "Service Connect request for service {{name}} was accepted",
            deleted: "Service Connect request for service {{name}} was deleted",
            notFound: "This request has already been accepted / declined."
        }
    },
    autocomplete: {
        name: "Name",
        description: "Description",
        email: "Email",
        admin: "Super user",
        organisations: "Organisations",
        collaborations: "Collaborations",
        link: "Link",
        noResults: "No results",
        resultsLimited: "More entries matched than can be shown, please narrow your search..."
    },
    history: {
        changes: "Changes",
        detail: "Detail",
        actions: {
            "1": "New",
            "2": "Updated",
            "3": "Deleted",
            approved: "Approved",
            denied: "Denied"
        },
        overview: "{{action}} {{collection}}{{name}}",
        none: "No history to display",
        key: "Attribute",
        oldValue: "Old value",
        newValue: "New value",
        parentNew: "New {{collection}} added to {{parent}}",
        parentUpdated: "Existing {{collection}} was updated and is linked to {{parent}}",
        parentDeleted: "Existing {{collection}} deleted from {{parent}}",
        activities: {
            tables: "All audit log tables",
            tablesTooltip: "Select all tables that you want to include If you select none then no filtering is done on table type.",
            tablesPlaceHolder: "Select tables",
            submit: "Fetch"
        },
        export: "Export to JSON",
        searchPlaceholder: "Search... ",
        includeServices: "Show services",
        includeMembers: "Show members",
        includeConnections: "Show connections",
        includeProperties: "Show properties",
        tables: {
            "api_keys": "Organisation API tokens",
            "aups": "Acceptable User Policy",
            "collaboration_memberships": "Collaboration membership",
            "collaboration_memberships_groups": "Group membership",
            "collaboration_requests": "Collaboration request",
            "collaborations": "Collaboration",
            "groups": "Group",
            "groups_invitations": "Group invitation",
            "invitations": "Invitation",
            "join_requests": "Join request",
            "organisation_invitations": "Organisation invitation",
            "organisation_memberships": "Organisation membership",
            "organisation_aups": "Organisation AUP",
            "organisations": "Organisation",
            "organisations_services": "Organisation service",
            "service_connection_requests": "Service connection request",
            "service_groups": "Service groups",
            "services": "Service",
            "services_collaborations": "Collaboration service",
            "service_requests": "Service registration requests",
            "users": "User",
            "ip_networks": "IP network",
            "user_ip_networks": "User IP addresses",
            "suspend_notifications": "Suspend notifications",
            "schac_home_organisations": "Schac home organisations",
            "user_names_history": "Username history",
            "ssh_keys": "SSH key",
            "user_mails": "User emails",
            "service_aups": "Service AUPs",
            "user_tokens": "User tokens",
            "service_invitations": "Service invitation",
            "service_memberships": "Service membership",
            "tags": "Label",
            "service_tokens": "Service tokens (any)"
        }
    },
    serviceRequest: {
        title: {
            open: "{{requester}} has requested to register the service '{{name}}'.",
            approved: "{{requester}} service registration was approved.",
            denied: "{{requester}} service registration was denied."
        },
        request: "Request {{id}}",
        requester: "Requester",
        approved: "Approved",
        rejectionReason: "Denied reason",
        denyConfirmation: "Are you sure you want to deny this service registration?",
        deleteConfirmation: "Are you sure you want to delete this service registration?",
        approve: "Approve",
        deny: "Deny",
        metaData: "The SAML metadata",
        showMetaData: "Show the SAML metadata",
        status: "Status",
        statuses: {
            all: "All ({{nbr}})",
            open: "Open",
            denied: "Denied",
            approved: "Approved"
        },
        flash: {
            approved: "Service {{name}} has been created",
            denied: "Service registration request for {{name}} has been denied",
            deleted: "Service registration request for {{name}} has been deleted",
        }
    },
    confirmation: {
        title: "Upgrade to platform admin",
        info: "You are designated to be a platform admin, but you'll need to verify this with a stronger verification. Hit the button below to start the upgrade of your profile.",
        confirmAccount: "Start verification"
    },
    inputField: {
        fileImport: "Upload",
    },
    confirmationDialog: {
        title: "Confirm",
        subTitle: "This action requires a confirmation",
        confirm: "Confirm",
        ok: "OK",
        cancel: "Cancel",
        leavePage: "Do you really want to leave this page?",
        leavePageSub: "Changes that you made will not be saved.",
        stay: "Stay",
        leave: "Leave"
    },
    tabs: {
        invitation_form: "Invitation details",
        invitation_preview: "Invitation preview",
        form: "Details",
        history: "History"
    },
    error_dialog: {
        title: "Unexpected error",
        subTitle: "An error has occurred",
        body: "This is embarrassing; an unexpected error has occurred. It has been logged and reported. Please try again...",
        deadEnd: "Nowhere to go from here",
        ok: "Close"
    },
    not_found: {
        title: "404",
        description_html: "The requested page could not be found",
        loginLink: "Log in"
    },
    footer: {
        support: "Support",
        supportLink: "https://edu.nl/vw3jx",
        terms: "Terms of Use",
        termsLink: "https://edu.nl/6wb63",
        privacy: "Privacy policy",
        privacyLink: "https://edu.nl/fcgbd",
        surfLink: "https://surf.nl",
    },
    system: {
        title: "System maintenance",
        runDailyJobsInfo: "Run the daily cron job to send notification mails and potentially suspend users that have not logged in for the configured period",
        runExpiredCollaborations: "Run the job to expire collaborations that have an expiration date before today",
        runExpiredMemberships: "Run the job to expire memberships that have an expiration date before today",
        runSuspendedCollaborations: "Run the job to suspend collaborations that have had no activity for X days",
        runOrphanUsers: "Run the job to delete orphan users (e.g. no membership, join request and collaboration requests)",
        runInvitationReminders: "Run the job to send reminder mails to all invitations that expire in X days",
        runInvitationExpirations: "Run the job to delete invitations that have expired X days",
        runDailyJobs: "Trigger",
        showDailyJobs: "Show",
        showDailyJobsInfo: "Show all the scheduled Cron jobs",
        parseMetaData: "Parse",
        showParsedMetaDataInfo: "Parse the IdP MetaData",
        jobName: "Name",
        jobNextRun: "Next scheduled run-time",
        runOutdatedRequestsInfo: "Run the cron job to report all open outdated join requests and new collaboration requests for the configured threshold",
        runOpenRequestsInfo: "Run the weekly cron job to report all open requests for CO, Org and Service admins",
        runOutdatedRequests: "Run",
        runCleanedRequestsInfo: "Run the cron job to delete all outdated approved / denied join requests and collaboration requests for the configured threshold",
        runCleanedRequests: "Delete",
        runSweepResults: "Run the cron job to sweep all SCIM enabled services",
        runSweep: "Run",
        action: "Action",
        results: "Results",
        warning_suspend_notifications: "Warning suspension notification email sent",
        suspended_notifications: "Users suspended and suspension notification email sent",
        warning_deleted_notifications: "Warning deletion notification email sent",
        deleted_notifications: "Users deleted and deletion notification email sent",
        collaborations_deleted: "Expired collaborations deleted",
        collaborations_expired: "Collaborations expired",
        collaborations_suspended: "Collaborations suspended",
        collaborations_warned: "Collaboration admins warned",
        orphan_users: "Deleted orphan users",
        memberships_deleted: "Expired memberships deleted",
        memberships_expired: "Memberships expired",
        memberships_warned: "Membership users warned",
        runDbStatsInfo: "Database table counts",
        userLoginInfo: "Login counts per type",
        runDbStats: "Fetch",
        name: "Table name",
        count: "Nbr of records",
        activity: "All recent activity from the audit logs ({{count}})",
        searchPlaceholder: "Search history...",
        searchPlaceholderServer: "Search in table audit logs (e.g. server side)...",
        runDbSeedConfirmation: "Are you absolutely sure? This will delete all current data",
        runDbSeedInfo: "Delete all data and insert the <strong>TEST</strong> seed",
        runDbDemoSeedInfo: "Delete all data and insert the <strong>TEST+DEMO</strong> seed",
        runDbSeed: "Run",
        runClearAuditLogsConfirmation: "Are you absolutely sure you want to delete all entries from the audit logs?",
        cleanSlate: "Delete everything",
        runCleanSlate: "Are you absolutely sure you want to delete all data?",
        clear: "Clear",
        reload: "Reload",
        seedResult: "{{seed}} data is inserted in {{ms}} ms.",
        toggleJson: "Toggle view",
        suspendedUsers: {
            title: "The following users have been suspended.",
            titleZeroState: "There are no suspended users.",
            email: "Email",
            name: "Name",
            lastLogin: "Last login",
            activate: "Activate"
        },
        resetTOTPRequestedUsers: {
            title: "The following users have requested a reset of their TOTP.",
            titleZeroState: "There are no users who have requested a TOTP reset.",
            reset: "Reset"
        },
        userlogins: {
            loginType: "Type",
            total: "Logins",
            succeeded: "Succeeded",
            failed: "Failed",
        },
        scim: {
            info: "If enabled there is a mock remote SCIM client available to test the SCIM events." +
                "Configure a Service with the following SCIM URL to start testing the SCIM functionality",
            services: "SCIM enabled Services",
            stats: "All API calls and state updates send to the mock SCIM endpoints are stored in an in-memory database.",
            retrieveStats: "Fetch stats",
            reRetrieveStats: "Refresh stats",
            clearStats: "Clear stats",
            clearConfirmation: "Are you sure you want to clear the SCIM statistics?",
            service: {
                scimUrl: "Scim URL",
                id: "Id",
                scimEnabled: "Scim enabled",
                sweepScimEnabled: "Sweep enabled",
                sweep: "Sweep",
            }
        },
        stats: {
            loading: "Loading, please wait..."
        },
        activityTable: {
            date: "Date",
            user: "User",
            action: "Action"
        },
        invitationReminders: {
            invitations: "Invitations resent",
            serviceInvitations: "Service invitations resent",
            organisationInvitations: "Organisation invitations resent",
        },
        invitationExpirations: {
            invitations: "Invitations deleted",
            apiInvitations: "API invitations deleted",
            serviceInvitations: "Service invitations deleted",
            organisationInvitations: "Organisation invitations deleted",
        },
        openRequests: {
            recipient: "Recipient",
            service_requests: "Service requests",
            service_connection_requests: "Service connection requests",
            join_requests: "Join requets",
            collaboration_requests: "Collaboration requests",
            collaboration_name: "CO name",
            requester: "Requester",
            organisation_name: "ORG name",
            service_name: "Service name",
        }
    },
    access: {
        full: "Full",
        limited: "Limited",
        readOnly: "Read-only",
        info: "{{access}} access as {{role}}",
        platformAdmin: "platform admin",
        orgAdmin: "organisation admin",
        orgManager: "organisation manager",
        coAdmin: "admin",
        coMember: "member",
        serviceAdmin: "service admin",
        serviceManager: "service manager",
        user: "user"
    },
    actionRoles: {
        orgAdmin: "Organisation admin",
        orgManager: "Organisation manager",
        coAdmin: "Admin",
        coMember: "Member",
        platformAdmin: "Platform admin",
        user: "User",
        serviceAdmin: "Service admin",
        serviceManager: "Service manager"
    },
    welcomeDialog: {
        title: "Welcome to {{name}}",
        hi: "Hi,",
        label: "",
        roleServiceAdmin: "You are invited to become a service <strong>{{role}}</strong>",
        roleOrganisationAdmin: "You are invited to become an organisation <strong>admin</strong>",
        roleOrganisationManager: "You are invited to become an organisation <strong>manager</strong>",
        roleCollaborationAdmin: "You are invited to become <strong>admin</strong> of this collaboration",
        roleCollaborationMember: "You are invited to become member of this collaboration",
        ok: "Accept, show me the {{type}}",
        organisation: "organisation",
        collaboration: "collaboration",
        service: "service",
        toggleRole: "Toggle role",
        infoMember: "Please be aware that on joining this collaboration, your personal data may be shared with the following services. Take a moment to review any policy documents provided by the service.",
        infoAdmin: "Please be aware that on becoming and admin of this collaboration, your personal data may be shared with the following services. Take a moment to review any policy documents provided by the service.",
        infoJoinRequest: " Before you can request to join this collaboration, you must review the acceptable use policy (AUP) and privacy policy of the service. Indicate below whether you accept them.",
        purpose: "Purpose of the collaboration",
        noServices: "No services connected yet.",
        proceed: "Proceed to {{name}}"
    },
    welcome: {
        title: "Hi {{name}},",
        subTitle: "With <strong>SURF Research Access Management</strong> you can log in to research services and collaborate with other researchers.",
        unknown: "an unknown institution",
        mysterious: "mysterious guest",
        joining: "Have been invited?",
        invited: "Have you been invited to join a collaboration? Use the link in the invite email.",
        creating: "Creating a new collaboration?",
        institutionCollNotAllowed: "You have logged in via <strong>{{name}}</strong> which does not allow you to create a collaboration. If that is unexpected, please contact <a href=\"mailto:sram-support@surf.nl\">sram-support@surf.nl</a> for help.",
        startCreateColl: "You have logged in via <strong>{{name}}</strong> which allows you to create a collaboration, to which you can connect services and invite researchers.",
        startRequestColl: "You have logged in via <strong>{{name}}</strong>, and you can request them to create a collaboration for you. After they approve your request, you can connect services and invite researchers.",
        instructions: "Message from <strong>{{name}}</strong>",
        createCollTxt: "Create collaboration",
        createCollRequestTxt: "Request collaboration",
    },
    feedback: {
        title: "Provide Feedback",
        info: "Like what you see? Have a suggestion? Let us know what you think here",
        disclaimer: "We will use this information to fix problems, improve our products and help you. " +
            "We may follow up with you regarding your feedback. " +
            "Please make sure the feedback does not contain any confidential, sensitive, or personal information. " +
            "For more information, please review our <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://edu.nl/fcgbd\">Privacy Notice</a>.",
        help: "Need help?",
        helpInfo: "For questions or issues, please reach out to us at <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a>",
        send: "Provide feedback",
        flash: "Your feedback has been sent. Thanks!"
    },
    landing: {
        header: {
            title: "Easy and secure access to research services for research collaborations",
            login: "Log in",
            sup: "New to SURF Research Access Management? Log in and create a collaboration with your institution.",
        },
        works: "How does it work?",
        adminFunction: "admin function",
        create: "Create",
        createInfo: "<p>Administrators create a collaboration page for their community.</p>" +
            "<p>They then select services from the catalog to make them available to collaboration members.</p>",
        invite: "Invite",
        inviteInfo: "<p>Administrators invite fellow researchers to their collaboration page.</p>" +
            "<p>They can also ask other administrators to help manage the collaboration.</p>",
        join: "Join",
        joinInfo: "<p>Invited researchers join by logging in with their institutional account or eduID.nl.</p><br/>",
        collaborate: "Collaborate",
        collaborateInfo: "<p>The secured collaboration page shows its members and gives access to the research services.</p>",
        footer: "<p>SURF Research Access Management is a service for access management of Dutch led research collaborations.</p>" +
            "<p>Do you want to know more? Please visit <a href='https://surf.nl/en/sram'>https://surf.nl/en/sram</a>.</p>"
    },
    tooltips: {
        members: "Composition of this collaboration",
        admins: "Admins of this collaboration",
        collaborationUrl: "More information about this collaboration",
        aup: "Acceptable use policy for this collaboration. This is an addition to the SURF Research Access Management AUP, which applies to all collaborations.",
        user: "User",
        admin: "Admin",
        invitations: "Invitee",
        joinRequest: "Join request",
        manager: "Manager",
        platformAdmin: "Goddess divinity",
        oneAdminWarning: "An organisation requires at least one admin.",
        serviceGroupConnectedNotDeletable: "It is not allowed to delete a Service group for a service that is currently connected; disconnect the service first."
    },
    notFound: {
        invitationNotFound: "This invitation does not exist (anymore). The invitation has probably already been used.</br>" +
            "Ask the person who invited you to send you a new one.",
        invalidSecondFactorUUID: "The link has expired. Please go back to the service where you we're trying to log in and try again.",
        invalidPamWebSSO: "Your session has expired. Please go back to the service you were trying to log in to and try again.",
        msg: "We can't seem to find that page. Sorry for that.</br></br>" +
            "Start over on the <a href='{{base_url}}'>homepage</a> or contact us at <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a>."
    },
    mfa: {
        register: {
            title: "Two factor authentication required",
            titleUpdate: "Add two factor authentication device",
            info1: "SURF Research Access Management now requires two-factor authentication. This means you have to set up an extra check to verify it's you.<br/><br/>" +
                "Did you already use a second factor to sign in at {{name}}? Please ",
            info11: " so we can fix that.",
            contactUs: "contact us",
            info2: "To setup two factor authentication, take the following steps:",
            step: "Stap {{nbr}}",
            getApp: "1. Add SURF Research Access Management to your authenticator app",
            unknownIdp: "your institution",
            unknownFeedbackIdp: "my institution",
            getAppUpdate: "2. Add SURF Research Access Management to your authenticator app",
            new: "new ",
            getAppInfo: "Open or install the Google (<a href=\"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US\" target=\"_blank\">Android</a> of <a href=\"https://apps.apple.com/us/app/google-authenticator/id388497605\" target=\"_blank\">iOS</a>) of Microsoft (<a href=\"https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US\" target=\"_blank\">Android</a> of <a href=\"https://apps.apple.com/us/app/microsoft-authenticator/id983156458\" target=\"_blank\">iOS</a>) Authenticator app on your {{new}}mobile device",
            addSRAM: "Tap the '+' icon in the app",
            scan: "Scan the QR code using your devices's camera",
            scanInfo: "Open the authenticator app and:",
            showSecret: "Show the shared secret",
            verificationCode: "2. Enter verification code",
            verificationCodeUpdate: "3. Enter verification code",
            verificationCodeInfo: "Enter the verification code you see in the app",
            verify: "Verify and activate",
            next: "Next",
            feedback: "I had to setup two factor authentication with SURF Research Access Management, but I already used two factor authentication to sign in with {{name}}.\n\n" +
                "Thanks for your feedback! Feel free to add anything else."
        },
        update: {
            info1: "To update your two-factor authentication, please follow the steps below.",
            info2: "You will need to have access to the authenticator app that you are currently using to sign in to SURF Research Access Management, as well as to the new authenticator app that you wish to use for future logins.",
            currentCode: "1. Enter current verification code",
            currentCodeInfo: "Enter the 6-digit verification code from your current authenticator app.",
            scanInfo: "Open your new authenticator app and:",
            verificationCodeInfo: "Once the QR-code above is scanned, enter the 6-digit verification code from your new authenticator app.",
            verify: "Verify and update",
            flash: "Your two-factor authentication has been updated to use your new authenticator app",
            preValidatedFlash: "Correct!"
        },
        verify: {
            title: "Enter your verification code",
            info1: "Enter the verificatie code from your authenticator app for <strong>SURF Research Access Management</strong> to verify your identity.",
            explanation: "Two-factor authentication",
            signIn: "Verify",
            problems: "Can't log in?",
            invalid: "Invalid verification code",
            resetRequest: "Reset your verfication code",
            resetToken: "Use a reset code"
        },
        profile: {
            name: "Two-factor authentication",
            no2FA: "Two-factor authentication is not configured.",
            tooltip: "Two-Factor Authentication (2FA) adds an additional layer of security to your profile. It requires an additional login credential –beyond just your username and password– to gain access, and getting that second credential requires access to your mobile authenticator app.",
            handledByIdp: "Two-factor authentication is handled by {{name}}.",
            institution: "your institution",
            handledBySRAM: "Two-factor authentication with authenticator app for mobile phone is configured.",
            edit: "Edit"
        },
        lost: {
            title: "Request a reset code",
            how: "How this works",
            info1: "You must ask an admin to reset your verification code",
            info2: "<strong>We will send them an email</strong> with your request",
            info3: "They will confirm you are you",
            info4: "They will send you a <strong>reset token</strong>",
            info5: "With that token you can reconfigure your two factor authentication",
            select: "Select who to ask for a reset token:",
            organisationNamePlatformAdmin: "",
            displayNamePlatformAdmin: "SRAM support",
            respondent: "Your request will be sent to:",
            message: "Message to the admin(s)",
            sendMail: "Request a reset token",
            flash: "Your request for a reset token has been sent"
        },
        reset: {
            title: "Reset your verification code",
            info1: "Enter your received reset token",
            submit: "Reset",
            resetCodePlaceholder: "Reset token",
            invalid: "Invalid reset token",
        }
    },
    sfo: {
        title: "You do not have access to <i>{{name}}</i>",
        info: "Bad luck...",
        info1: "<p>Access to <i>{{name}}</i> requires membership of a collaboration in SURF Research Access Management.</p><p>Contact your admins to invite you to a collaboration that grants access to this service.</p>",
        info2: "<p>Your profile in SURF Research Access Management is suspended, and therefore you don't have access to <i>{{name}}</i>.<p>Contact your admins to enable access to this service.</p>",
        info3: "You are logged in, but the service <i>{{name}}</i> is unknown to SURF Research Access Management.",
        info4: "<p>You are logged into SURF Research Access Management, but none of the collaborations you are member of grant you access to <i>{{name}}</i>.</p><p>Contact your admins to enable access to this service.</p>",
        info5: "<p>You are logged into SURF Research Access Management, but you don't have access to <i>{{name}}</i>. The collaboration that is connected to service <i>{{name}}</i> is inactive.</p><p>Contact your admins to enable access to this service.</p>",
        info6: "<p>You are logged into SURF Research Access Management, but you don't have access to <i>{{name}}</i>. Your collaboration membership which grants you access to <i>{{name}}</i> is expired.</p><p>Contact your admins to enable access to this service.</p>",
        ticket: "If you want you can contact SRAM support at " +
            "<a href='mailto:sram-support@surf.nl?subject={{subject}}&body=Entity ID: {{entityId}}%0D%0AIssuer ID: {{issuerId}}%0D%0AUser ID: {{userId}}%0D%0ATimestamp: {{timestamp}}'>sram-support@surf.nl</a> for help.",
        subject: "No access to service {{name}}",
        entityId: "Entity ID",
        issuerId: "Issuer ID",
        userId: "User ID",
        timestamp: "Timestamp"
    },
    unsuspend: {
        confirmation: "Are you sure you want to revert suspension this collaboration?",
        flash: "Collaboration {{name}} is active again"
    },
    resetActivity: {
        confirmation: "Are you sure you want to reset the last activity date for this collaboration?",
        flash: "Collaboration {{name}} last activity date has been reset"
    },
    activate: {
        confirmation: "Are you sure you want to clear the expiration date for this collaboration?",
        flash: "Collaboration {{name}} expiration date has been cleared"
    },
    organisationMembership: {
        membership: "Membership of {{name}} has no expiration date.",
        membershipWithExpiry: "Membership of {{name}} will expire at {{date}}.",
        expiredMembership: "This membership has expired at {{date}}. Update the expiration date to activate {{name}}'s membership.",
        member: "Membership",
        expiryDate: "Expiration date",
        expiryDateTooltip: "The expiration date of the membership. After this date the membership will be suspended and this member can no longer use the services",
        update: "Update",
        alreadyMember: "You are already a member of this CO and do not need to accept a new invitation.",
        alreadyMemberHeader: "Already member",
        status: {
            name: "Your membership",
            active: "Active",
            expired: "Membership expired",
            activeWithExpiryDate: "Expires on {{date}}",
            activeTooltip: "Your membership has no expiration date.",
            expiredTooltip: "Your membership has expired on {{date}}.",
            expiredTooltipMember: "Your membership has expired on {{date}}. Please contact the admins of this collaboration for help. ",
            activeWithExpiryDateTooltip: "Your membership is active, but it will expire at {{date}}. "
        }
    },
    serviceDetail: {
        deleteMemberConfirmation: "Are you sure you want to delete the selected service members and invitations?",
        deleteYourselfMemberConfirmation: "Are you sure you want to leave this service? You will have to be re-invited by an admin to rejoin.",
        resendInvitations: "Are you sure you want to resend all selected invitation?",
        downgradeYourselfMemberConfirmation: "Are you sure you don't want to be an admin anymore? You won't be able to revert this.",
        flash: {
            entitiesDeleted: "Members and invitations have been deleted",
            invitesResend: "Invitations for service {{name}} were resent.",
            memberUpdated: "The role of membership of {{name}} was updated to {{role}}",
        },
        gone: {
            member: "This membership does no longer exists. After closing this popup the memberships will be refreshed.",
            invitation: "This invitation has already been accepted / rejected and does no longer exists. After closing this popup the invitations will be refreshed."
        },
        admin: "Service admin",
        manager: "Service manager",
        intendedRole: "Role in the service",
        intendedRoleTooltip: "The service manager role is limited to managing collaboration connections"
    },
    scim: {
        scimEnabled: "SCIM push to service",
        scimDisclaimer: "SCIM push to service is disabled",
        scimEnabledTooltip: "Send this service SCIM event",
        scimClientEnabled: "SCIM pull by service",
        scimClientDisclaimer: "SCIM pull by service is disabled",
        scimClientEnabledTooltip: "Allow this service to call the SCIM endpoints",
        scimURL: "SCIM endpoint base URL",
        scimURLPlaceHolder: "https://service.com.scim",
        scimURLTooltip: "The SCIM endpoint's base URL to send SCIM events to",
        scimBearerToken: "SCIM token",
        scimBearerTokenTooltip: "The external SCIM token needed to authorize the SRAM calls to the SCIM endpoints using the bearer authorization",
        sweepScimEnabled: "SCIM sweeping",
        sweepScimEnabledTooltip: "A periodic check, and update if necessary, to keep the service's data in sync.",
        scimSweepDeleteOrphans: "Sweep deletes orphaned users, and empty collaborations and groups",
        scimSweepDeleteOrphansTooltip: "While sweeping, delete users without memberships, and collaboration and groups without members",
        sweepScimDailyRate: "Sweeps per day",
        sweepScimDailyRateTooltip: "How often within 24 hours the remote SCIM data is sweeped"
    },
    userTokens: {
        actionTitle: "New organisation API token",
        tokens: "Tokens",
        tokenEnabled: "User token introspection",
        tokenEnabledTooltip: "Allow this service to call the introspection endpoint to receive user information",
        userTokenDisclaimer: "User token introspection is disabled",
        pamWebSSOEnabled: "PAM web login",
        pamWebSSOEnabledTooltip: "Allow this service to call the PAM web login endpoints to log in an user on a terminal-based app (e.g., an SSH client)",
        tokenValue: "One-way hashed token",
        tokenValidityDays: "User token validity (days)",
        tokenValidityDaysTooltip: "The number of days the user tokens are valid",
        introspectionEndpoint: "Introspection endpoint URL",
        introspectionEndpointToken: "Introspection endpoint token",
        tokenDisclaimer: "Copy the token and store it somewhere safe. You can view this token only once here.<br><br>After pressing 'Add' you won't be able to see it again, except by deleting it and recreating a new token.",
        delete: "Remove token",
        deleteConfirmation: "Are you sure you want to delete this token? This action cannot be undone.",
        flash: {
            deleted: "Token {{name}} has been removed",
            reactivated: "Token {{name}} has been reactivated",
            created: "Token {{name}} was added",
            updated: "Token {{name}} is updated",
        },
        reset: {
            confirmation: "Are you sure you want to reset the token for {{name}}?",
            success: "Token has been reset.",
            info: "Copy and store the token somewhere safe. You can not see this token afterwards.",
            copy: "Copy",
            close: "Close",
        }
    },
    pamWebSSO: {
        title: "Log in to {{service}}",
        info: "Log in to get the verfication code required for the command line interface",
        proceed: "Log in",
        enterPin: "Your verification code",
        enterPinInfo: "Use this code in the command line interface to access {{service}}.",
        success: "All done",
        successInfo: "You can close this window.",
        timeOut: "Your session has expired",
        timeOutInfo: " Please go back to the service you were trying to log in to and try again.",
        denied: "Permission denied",
        deniedInfo: "Unfortunately, you don't have access to this service.",
        contact: "Please contact <a href='mailto:{{support}}'>{{support}}</a> to arrange access to this service."
    },
    serviceDetails: {
        details: "Service details",
        tokens: "Tokens",
        tokensTooltips: {
            scim: "A SCIM token enables the service to call the SCIM endpoints of SRAM",
            pam: "A PAM web login token enables the service to use the PAM web login module",
            introspection: "A user introspection token enables the service to introspect users"
        },
        hashedToken: "Hashed token",
        tokenValue: "**** **** ****",
        tokenType: "Type",
        tokenTypeTooltip: "You can only use this token for {{tokenType}} requests",
        tokenDeleteConfirmation: "Are you sure you want to remove this service token?",
        disableTokenConfirmation: "Are you sure you want to disable {{type}} functionality for this service? The {{count}} existing {{tokens}} will be deleted.",
        multipleTokens: "tokens",
        singleTokens: "token",
        noTokens: "There are no service tokens",
        enableTokens: "To create tokens you need to enable {{action}}",
        secretDisclaimer: "You can view this token only once. Copy it and store it somewhere safe.<br><br>If the token is lost, delete it and create a new token.",
        secret: "Token",
        secretValue: "One-way hashed token",
        secretTooltip: "The token to use in the authorization header",
        description: "Description",
        descriptionPlaceHolder: "Description for this token",
        descriptionTooltip: "An optional description explaining the use of this token",
        backToApiKeys: "Back to tokens",
        addToken: "+ Add token",
        toc: {
            general: "General",
            contacts: "Contacts",
            policy: "Policy",
            ldap: "LDAP",
            tokens: "User introspection tokens",
            pamWebLogin: "PAM web login",
            SCIMServer: "SCIM push to service",
            SCIMClient: "SCIM pull by service",
            OIDC: "OpenID Connect",
            SAML: "SAML2.0",
            Export: "Export"
        },
        updateDisabled: "Some fields have invalid or are missing values in the sections {{invalid}}."
    },
    expirations: {
        expires: "Expires {{relativeTime}}",
        expired: "Expired {{relativeTime}}",
        never: "Never expires",
        activity: {
            now: "Just now",
            seconds: "Today",
            minute: "Today",
            minutes: "Today",
            hour: "Today",
            hours: "Today",
            day: "Yesterday",
            days: "This week",
            week: "This week",
            weeks: "This month",
            month: "Last month",
            months: "%s months ago",
            year: "1 year ago",
            years: "%s years ago"
        },
        ago: {
            now: "just now",
            seconds: "%s seconds ago",
            minute: "1 minute ago",
            minutes: "%s minutes ago",
            hour: "1 hour ago",
            hours: "%s hours ago",
            day: "1 day ago",
            days: "%s days ago",
            week: "1 week ago",
            weeks: "%s weeks ago",
            month: "1 month ago",
            months: "%s months ago",
            year: "1 year ago",
            years: "%s years ago"
        },
        in: {
            now: "right now",
            seconds: "in %s seconds",
            minute: "in 1 minute",
            minutes: "in %s minutes",
            hour: "in 1 hour",
            hours: "in %s hours",
            day: "in 1 day",
            days: "in %s days",
            week: "in 1 week",
            weeks: "in %s weeks",
            month: "in 1 month",
            months: "in %s months",
            year: "in 1 year",
            years: "in %s years"
        }
    },
    error: {
        message: "We seem to have run into an unknown error. Sorry for that.</br>" +
            "Start over on the <a href='/'>homepage</a> or contact us at <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a>.",
        ssid_failed: "SURF Research Access Management requires two-factor authentication, an extra check to verify it's you.</br>" +
            "Unfortunately, this authentication from SURFsecureID has just failed.</br></br>" +
            "Please copy & paste the following information and contact SRAM support at <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a> for help.",
        defaultCode: "urn:oasis:names:tc:SAML:2.0:status:Responder",
        defaultMessage: ""
    },
    gallery: {
        upload: "Upload your own image",
        select: "Select from gallery"
    },
    missingAttributes: {
        info: "Something went wrong, we didn't receive all the required login information. Please try logging in again, if possible using a incognito browser window.",
        contact: "If the problem reoccurs, please contact us at <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a> for help"
    },
    coPageHeaders: {
        membersGroups: "{{memberCount}} {{members}} & {{groupCount}} {{groups}}",
        singleMember: "member",
        multipleMembers: "members",
        singleGroup: "group",
        multipleGroups: "groups",
        no: "No",
        visit: "Visit website",
        membership: "Member since {{date}}",
        expiresTooltip: "Membership expires {{date}}"
    },
    servicePageHeaders: {
        notConnected: "<span class='not-connected'>Not connected</span> to any collaboration",
        allConnected: "Connected to <span class='all-connected'>all</span> collaborations",
        connectedToSingle: "Connected to {{count}} collaboration",
        connectedToMultiple: "Connected to {{count}} collaborations",
        notAvailable: " because it is <span class='not-available'>not available</span> for any organisation",
        availableSingle: " & available for {{count}} organisation",
        availableMultiple: " & available for {{count}} organisations",
        all: "all",
        launch: "Open the service",
        visit: "visit the website"
    },
    myRequests: {
        requestType: "Request type",
        types: {
            joinRequest: "Collaboration join request",
            collaborationRequest: "New collaboration request",
            serviceRequest: "Service registration",
            serviceConnectionRequest: "Service connection request"
        },
        name: "Name",
        description: "Description",
        organisationName: "Organisation / institution",
        notApplicable: "N/A"
    },
    units: {
        column: "Units",
        label: "Units (e.g. department or project)",
        add: "+ Add unit",
        confirmation: "Are you sure you want to remove unit {{name}}?",
        used: "It is being used by:",
        collaboration_requests: "Collaboration requests",
        invitations: "Organisation invitations",
        collaborations: "Collaborations",
        organisation_memberships: "Memberships",
        collaboration: "Relevant units (e.g. department or project)",
        unitsPlaceHolder: "Select an unit",
        collaborationRequest: "Organisational units (optional)",
        filter: "All units",
        invitationLabel: "COs of which units to be managed",
        invitationTooltip: "Select the organisation units to scope the responsibilities of the organisation manager",
        all: "All units",
        specify: "Specify units",
        back: "Back to all admins",
        editRole: "Edit organisation manager details",
        duplicated: "There is already an unit named {{name}} for this organisation",
        unitRequired: "You need to select at least one unit"
    },
    serviceDenied: {
        loginPrevTitle: "Could you login previously?",
        loginPrev: [
            "Make sure you are using the correct account.",
            "Check if you are still a member of the CO that organised your permission.",
            "If something else is going wrong, please contact us at " +
            "<a href='mailto:sram-support@surf.nl?subject={{subject}}&body=Entity ID: {{entityId}}%0D%0AIssuer ID: {{issuerId}}%0D%0AUser ID: {{userId}}%0D%0ATimestamp: {{timestamp}}'>sram-support@surf.nl</a>."
        ],
        neverBeenBeforeTitle: "Have you never logged on before?",
        neverBeenBefore: [
            "If someone pointed you to <strong>{{serviceName}}</strong>, reach out to them on how to gain access via a research collaboration.",
            "Learn what <a href=\"https://surf.nl/sram\" target=\"_blank\" rel=\"noopener noreferrer\">SRAM is all about</a>."],
        ticketInfoTitle: "Session information"
    },
    collaborationsOverview: {
        welcome: "Welcome {{name}}",
        select: "Select your collaboration",
        request: "Request new collaboration",
        create: "Create new collaboration",
        viewRequests: "view requests",
        open: "Open"
    },
    countDownDialog: {
        title: "Access to {{name}}",
        subTitle: "To access service {{name}} you will have to be provisioned. This unfortunately takes some time, grab a cup of ☕️.",
        info: "You will be redirected in <span class='counter'>{{counter}}</span> seconds."
    }
};

export default en;
